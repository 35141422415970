<template lang="pug">
v-container(fluid)
  v-alert(
    class="text-h4"
    border="bottom"
    color="blue-grey"
    dark
    v-html="alertMessage")
    
  v-card(class="mt-7")
    v-form
      v-row(class="px-2")
        v-col(cols="12" md="3")
          v-text-field(
            v-model="npn"
            clearable
            label="Número predial o Número predial anterior"
            required)
        v-col(cols="12" md="3")
          fmi-picker(v-model="propertyRecord" ref="fmiNumber")
        v-col(class="pt-6" cols="12" md="3")
          v-autocomplete(
            v-model="selectedCity"
            :loading="loadingCities"
            :items="cities"
            :search-input.sync="searchCities"
            cache-items
            class=""
            item-value="codigo"
            item-text="nombre"
            dense
            outlined
            hide-no-data
            hide-details
            label="Seleccione la Ciudad/Municipio")
            template(v-slot:selection="data")
              v-chip(
                class="ma-2"
                close
                @click:close="selectedCity = null")
                | {{data.item.nombre}} ({{ data.item.codigo }}) | {{ data.item.dpto_municipio.nombre }}
            template(v-slot:item="data")
              v-list-item-content
                v-list-item-title(v-html="data.item.nombre")
                v-list-item-subtitle(v-html="data.item.dpto_municipio.nombre")
        v-col(cols="12" md="3")
          v-btn(
            :disabled="loading"
            :loading="loading"
            color="green"
            small
            class="ma-2 white--text"
            @click="search")
            v-icon(left dark) mdi-magnify
            | consultar
          v-btn(
            :disabled="loading"
            :loading="loading"
            color="red"
            small
            class="ma-2 white--text"
            @click="clear")
            v-icon(left dark) mdi-broom
            | limpiar
          v-btn.mx-2(
            fab
            dark
            small
            title="Desplegar Ayuda"
            @click="showHelp('CadastralHelp', 'Ayuda búsqueda en Bases Castrales', '500')"
            color='primary')
            v-icon(dark)
              | mdi-help-circle-outline
  v-row(class="mt-2")
    v-col(cols="6")
      search-location-info

    v-col(cols="6")
      record-one-info

    v-col(cols="12")
      record-historical
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import SearchLocationInfo from '@/components/folios/SearchLocationInfo.vue';
import RecordOneInfo from '@/components/folios/RecordOneInfo.vue';
import FmiPicker from '@/components/ui/FmiPicker.vue';
import RecordHistorical from '@/components/folios/RecordHistorical.vue';
import commonArkandhaMixin from '@/mixins/commonArkandhaMixin.js';

export default {
  name: 'FolioSearch',

  components: {    
    SearchLocationInfo,
    RecordOneInfo,
    RecordHistorical,
    FmiPicker,
  },

  mixins: [commonArkandhaMixin],

  data: () => ({
    npn: '',
    cities: [],
    npnOld: '',
    propertyRecord: '',
    selectedCity: null,
    loading: false,
    queryString: '',
    alertMessage: `Consulta por números prediales<br>
    <span class="text-body-1">Recuerde que se <b>almacenará</b> un registro en las consultas realizadas por cada usuario</span>`
  }),

  mounted() {
    if ('fmi' in this.$route.query || 'code' in this.$route.query) {
      // Inicializar el numero de matricula inmobiliaria
      const { fmi } = this.$route.query;
      let { code } = this.$route.query;
      console.log(code);
      if (code === undefined) {
        code = '';
      }
      this.npn = code;
      if (fmi !== undefined) {
        const [circle, number] = fmi.split('-')
        this.$refs.fmiNumber.selectedCircle = circle;
        this.$refs.fmiNumber.recordText = number;
        this.$refs.fmiNumber.valid = true;
        this.propertyRecord = fmi;
      }
      // ejecutar la funcion de consulta
      setTimeout(() => this.search(), 1000);
      // eliminar el parametro de la ruta
      window.history.replaceState({}, document.title, "/" + "search");
    }
  },
  
  methods: {
    ...mapActions('folios', ['folioSearch']),
    ...mapMutations('ui', ['SHOW_ALERT']),
    ...mapMutations('folios', ['CLEAR_SEARCH']),
    getCity(e) {
      return `${e.nombre} - ${e.codigo}`
    },
    // metodo para realizar la consulta con los parametros seleccionados
    async search() {
      this.CLEAR_SEARCH();
      if (this.npn === '' && this.npnOld === '' && this.propertyRecord === '') {
        this.SHOW_ALERT({
          alertMessage:'Debe ingresar por lo menos un valor para la búsqueda',
          alertType: 'error'
        });
        return;
      }
      this.loading = true;

      try {
        await this.folioSearch({
          npn: this.npn,
          npnOld: this.npnOld,
          propertyRecord: this.propertyRecord,
          cityCode: this.selectedCity ? this.selectedCity : ''
        });
      } catch (error) {
        this.SHOW_ALERT({
          alertMessage:'Ocurrio un error al cargar la información',
          alertType: 'error'
        });
      }      
      this.loading = false;
    },
    // metodo para limpiar los criterio de consulta
    clear() {
      this.npn = '';
      this.$refs.fmiNumber.clear();
      this.selectedCity = null;
    }
  }
};
</script>