<template lang="pug">
v-card(
  class="mx-auto"
  max-width="400")
  v-list-item(two-line)
    v-list-item-content
      v-list-item-subtitle(
        :style="{color: `${currentFolio.estado == 'Activo' ? '#2E7D32' : '#C62828'}!important`}"
        class="text-h6 text-right") {{currentFolio.estado }}
      v-list-item-title(class="text-h5") {{currentFolio.numero_matricula}}
      v-list-item-subtitle
        | <b>Tipo predio:</b>
        |{{currentFolio.tipo_predio}}        

  v-card-text
    v-alert(
      class="mb-0"
      dense
      prominent
      type="success")
      span(class="text-caption")
        | Este predio no presenta riesgos como anotaciones de limitación al dominio,
        | falsas tradiciones. (INFORMACION QUEMADA)

    v-list-item(two-line)
      v-list-item-content          
        v-list-item-subtitle
          v-icon(class="mr-2" title="Fecha de apertura") mdi-calendar-month
          | <b>Fecha apertura: </b>
          | {{currentFolio.fecha_apertura}}
        v-list-item-subtitle
          v-icon(class="mr-2" title="Circulo registral") mdi-office-building 
          | <b>Círculo registral: </b>
          | {{currentFolio.nombre_circulo}}
        v-list-item-subtitle
          v-icon(class="mr-2" title="Ubicación") mdi-map-marker
          | <b>Localización: </b>
          | {{currentFolio.departamento}} - {{currentFolio.municipio_txt}}

    v-divider
    div(class="d-flex")
      v-btn(
        color="info"
        small
        class="ma-2 white--text"
        @click="detailFolio(currentFolio.id)") Ver detalle
        v-icon(
          right
          dark) mdi-information
      v-spacer 
      v-btn(
        color="info"
        small
        class="ma-2 white--text"
        @click="analysisFolio(currentFolio.id)") Ver diagnóstico
        v-icon(
          right
          dark) mdi-scale-balance
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import folioMixin from '@/mixins/folioMixin.js';

export default {
  name: 'FolioSummary',

  mixins: [folioMixin],

  data: () => ({
    selectedAnnotation: null,
  }),
  
  computed: {
    ...mapState('folios', ['viewedMeasure', 'currentFolio']),
    selectedMeasure: {
      get() {
        return this.$store.state.folios.selectedMeasure;
      },
      set(value) {
        return this.$store.commit('folios/SELECT_MEASURE', value);
      }
    },
  },

  methods: {
    ...mapMutations('ui', ['SET_DIALOG_CONTENT', 'TOGGLE_DIALOG', 'SET_DIALOG_TITLE']),
    ...mapMutations('folios', ['SET_ANNOTATION', 'SELECT_ANNOTATION']),
    selectAnnotation(annotation) {
      this.SET_DIALOG_CONTENT('AnnotationSummary');
      this.SET_DIALOG_TITLE('Detalle de la Anotación');
      this.SET_ANNOTATION(annotation);
      this.CHANGE_VALUE({
        varName: 'modalWidth',
        value: '100%',
      });
      this.TOGGLE_DIALOG(true);
    },
  },
  
}
</script>