<template lang="pug">
v-card(height="auto")
  v-toolbar(
    height="40"
    :color="folioColor"
    dark)
      v-toolbar-title Análisis jurídico de las anotaciones

  v-card-title(class="text-h5" color="red")
    | Listado de las condiciones Jurídicas
    v-spacer
    v-btn(
      color="info"
      class="ma-2 white--text"
      @click="showTimeline")
      | Ver línea de tiempo
      v-icon(
        right
        dark) mdi-sort-clock-descending-outline
    v-btn(
      color="info"
      class="ma-2 white--text"
      @click="showHistogram")
      | Ver histograma
      v-icon(
        right
        dark) mdi-chart-bar
  v-row(class="px-4")    
    v-col(cols="6")
      annotation-analysis(:annotations="currentFolio.anotaciones")
    v-divider(vertical)
    v-col(cols="6")
      annotation-detail(        
        :annotation="annotation"
        v-if="annotation")
      div(v-else) Seleccione una anotación
    
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import AnnotationAnalysis from '@/components/annotations/AnnotationAnalysis';
import AnnotationDetail from '@/components/annotations/AnnotationDetail';
import folioMixin from '@/mixins/folioMixin.js';

export default {
  name: 'FolioAnnotationsAnalysis',

  components: {
    AnnotationAnalysis,
    AnnotationDetail,
  },

  mixins: [folioMixin],

  data: () => ({
    annotation: null
  }),

  computed: {
    ...mapGetters('folios', ['folioColor']),
    ...mapState('folios', ['currentFolio', 'viewedAnnotation']),
  },

  created() {
    this.$bus.$on('select-annotation', (annotation) => {
      this.annotation = annotation.annotation;
    });
  },

  methods: {
    ...mapMutations('ui', [
      'TOGGLE_DIALOG',
      'SET_DIALOG_CONTENT',
      'SET_DIALOG_TITLE',
      'CHANGE_VALUE',
    ]),
    showHistogram() {
      this.SET_DIALOG_CONTENT('AnnotationHistogram');
      this.SET_DIALOG_TITLE('Histograma de anotaciones');
      this.CHANGE_VALUE({
        varName: 'modalWidth',
        value: '500',
      });
      // this.CHANGE_VALUE({
      //   varName: 'currentProperties',
      //   value: {
      //     style: "height: 500px"
      //   },
      // });
      this.TOGGLE_DIALOG(true);
    }
  },
}
</script>
