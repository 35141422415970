import VurService from '@/services/VurService.js';
import FoliosService from '@/services/FoliosService.js';
import { makeid } from '../../utils.js';
import { staticScrapperUrl } from '@/env.js';

const MAP_RELATIONS = {
  ROOT: 'Raíz',
  CHILD: 'Segregada',
  PARENT: 'Matriz'
}

const vur = {
  namespaced: true,

  state: {
    registrationCircles: [],
    isValidating: false,
    selectedCertificate: null,
    // almacena el listado de certificados que se van a descargar en el vur
    certificates: [],
    loadingExcel: false,
    excelError: '',
    vurListIcon: 'mdi-file-document-remove',
    vurListMessage: 'Aún no hay certificados en la lista.',
    // indica si la famulia se va a consultar utilizando el vur
    vurMode: false,
    // estado que indica el estado de descarga de la familia vur
    loadingVurFamily: false,
    // se almacenan los logs del procesamineto de la descarga de la fa,ilia
    vurFamilyLogs: [],
    // variable que indica que se estan consultando propietarios VUR
    loadingVurOwners: false,
    identificationSupportUrl: '',
    nameSupportUrl: '',
    // variable para almacenar el id de la tarea celery
    celeryTaskId: '',
    // estado para alamacenar el estado de carga de consulta vur masiva
    loadingVur: false,
  },

  actions: {
    async getCircles({ commit }) {
      const token = localStorage.getItem('token');
      try {
          const response = await VurService.getCircles(token);
          if (response) {
            commit('SET_CIRCLES', response.data);
          }
      } catch (error) {
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al cargar los circulos registrales
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    },
    async validateCertificate({ commit, state }, certificateNumber) {
      state.isValidating = true;
      const certificateId = makeid(8);
      const token = localStorage.getItem('token');
      let folioId = null;
      let valid = false;
      let identification = 'Sin dirección';
      let polygon = null;
      let registerData = null;
      let matchDb = '';
      let cadastralDbs = [];
      // validar si el certificado puede ser consultado en el VUR
      try {
        const response = await VurService.validateCertificate(certificateNumber);
        if (response.status == 200) {
          if ('valid' in response.data) {
            valid = response.data.valid;
            identification = response.data.address;
          }
        }
      } catch (error) {
        console.log(error)
      }
      // Obtener detalles del predio consultando la base interna de arkandha
      try {
        const response = await FoliosService.folioSearch('', '', certificateNumber, '', token);
        if (response) {
          if (response.data.folio_id) {
            folioId = response.data.folio_id;
          }
          if (response.data.poligono) {
            polygon = response.data.poligono;
          }
          if (response.data.registros) {
            registerData = response.data.registros;
          }
          if (response.data.match_database) {
            matchDb = response.data.match_database;
          }
          if (response.data.used_databases) {
            cadastralDbs = response.data.used_databases;
          }
        }
      } catch (error) {
        console.log(error);
      }
      commit('ADD_CERTIFICATE', {
        certificateId,
        certificateNumber,
        valid,
        identification,
        polygon,
        registerData,
        folioId,
        sources: [],
        matchDb,
        cadastralDbs,
      });
      state.isValidating = false;
    },
    // Acción para obtener las credenciales para acceder al VUR del usario
    // eslint-disable-next-line
    getVurCredentials({}) {
      const token = localStorage.getItem('token');
      return VurService.getVurCredentials(token)
        .then((resp) => {
          return resp.data;
        })
        .catch((err) => {
          console.log(err);
          return `Ocurrio un error: ${err.response.status} - ${err.response.data.detail}`;
        });
    },
    // Acción para el cambio de credenciales VUR
    // eslint-disable-next-line
    changeVurCredentials({commit}, { username, password }) {
      return VurService.changeVurCredentials(username, password)
        .then((resp) => {
          console.log('entro SUCCESS CHANGE VURCREDENTIALS', resp);
          // if (resp.data) {
          //   const token = resp.data.access_token;
          // }
          commit('auth/ADD_PERMISSION', 'can_use_vur', {root: true});
          return 'success';
        })
        .catch((err) => {
          console.log(err);
          return `Ocurrio un error: ${err.response.status} - ${err.response.data.detail}`;
        });
    },
    // Accion para enviar la lista de certificados al VUR
    async uploadCertificates({ commit, state }, isMassive) {
      state.excelError = '';
      // resetear el id de la tarea celery
      state.celeryTaskId = '';
      state.loadingVur = true;
      try {
        // TODO con numeros de FMI validos
        // const rawCertificates = state.certificates.filter((x) => x.processed === false && x.valid === true);
        const rawCertificates = state.certificates.filter((x) => x.processed === false);
        const token = localStorage.getItem('token');
        const response = await VurService.uploadCertificates(rawCertificates, token, isMassive);
        if (response) {
          commit('UPDATE_CERTIFICATES');
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se cargaron correctamente los certificados en el sistema',
            alertType: 'success'
          }, {root: true});
          // asignar el id de la tarea de celery
          state.celeryTaskId = response.data.task_id;
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error almacenar los certificados en el sistema
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
          state.loadingVur = false;
      }
    },
    async uploadFile({ commit, state, dispatch }, file) {
      try {
        state.loadingExcel = true;
        const token = localStorage.getItem('token');
        const response = await VurService.uploadFile(file, token);
        if (response) {
          console.log(response);
          const folios = response.data;
          const errorFolios = folios.filter((x) => x.valid === false);
          commit('WRITE_ERROR', errorFolios);
          const validFolios = folios.filter((x) => x.valid === true);
          dispatch('populateFolios', validFolios);
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se cargo correctamente el listado en el sistema',
            alertType: 'success'
          }, {root: true});
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al cargar el listado en el sistema
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
      state.loadingExcel = false;
    },
    populateFolios: ({commit}, validFolios) => {
      for (let index = 0; index < validFolios.length; index++) {
        const folio = validFolios[index];
        const certificateId = makeid(8);
        let valid = false;
        let identification = 'Sin dirección';
        let polygon = null;
        let registerData = null;
        let folioId = null;
        commit('ADD_CERTIFICATE', {
          certificateId,
          certificateNumber: folio.record,
          valid,
          identification,
          polygon,
          registerData,
          folioId,
          sources: []
        });
      }
    },
    // Accion para enviar la lista de certificados al VUR
    async sendVurPerson({ commit, state, rootState }) {
      state.excelError = '';
      // vaciar la lista certificados y cambiar mensaje
      state.certificates = [];
      state.loadingVurOwners=true;
      state.identificationSupportUrl='';
      state.nameSupportUrl='';
      // resetear el id de la tarea celery
      state.celeryTaskId = '';
      commit('UPDATE_LIST_VUR_MESSAGE', {
        icon: 'mdi-file-document-remove',
        message: 'Aún no hay certificados en la lista.'
      });
      try {
        const subjectData = rootState.restrictions.targetData;
        console.log('action sendVurPerson', subjectData);
        const token = localStorage.getItem('token');
        const response = await VurService.sendVurPerson(subjectData, token);
        if (response) {
          commit('UPDATE_CERTIFICATES');
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se enviaron correctamente los datos al sistema',
            alertType: 'success'
          }, {root: true});
          // asignar el id de la tarea de celery
          state.celeryTaskId = response.data.task_id;
        }
      } catch (error) {
          console.log(error);
          state.loadingVurOwners=false;
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error almacenar los certificados en el sistema
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    },
    // Accion para cargar los predios de la busqueda por persona
    loadOwnerStates({ commit }, {estates, message}) {
      console.log(message);
      for (let index = 0; index < estates.length; index++) {
        const estate = estates[index];
        const certificateId = makeid(8);
        let valid = true;
        let identification = estate.direccion;
        let polygon = null;
        let registerData = null;
        let folioId = null;
        let sources = estate.fuentes;
        commit('ADD_CERTIFICATE', {
          certificateId,
          certificateNumber: `${estate.circulo}-${estate.numeroMatricula}`,
          valid,
          identification,
          polygon,
          registerData,
          folioId,
          sources,
        });
      }
    },
    async searchVurFamily({ commit, state, rootState }, {propertyRecord, downloadParent, downloadChildren}) {
      /**
       * Acción para descargar la tradicion del folio desde el VUR
       *
       * @param {propertyRecord} string El número del folio de matrícula inmobiliaria
       * @param {downloadParent} bool Cuando se vana descargar las matriculas matrices
       * @param {downloadChildren} bool Cuando se vana descargar las matriculas segregadas
       * @returns {} No retorna nada
       * @example
       * $store.dispatch('searchVurFamily', {
       *  propertyRecord: '070-26232',
       *  downloadParent: true,
       *  downloadChildren: false,
       * })
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      rootState.folios.graphData = null;
      rootState.folios.traditionList = [];
      rootState.folios.segregations = [];
      rootState.folios.folioFamily = [];
      rootState.folios.folioTradition = [];

      state.loadingVurFamily = true;
      state.vurFamilyLogs = [];

      //fijar el folio de consulta
      rootState.folios.graphFolioNumber = propertyRecord;
      // resetear el id de la tarea celery
      state.celeryTaskId = '';
      try {
        const token = localStorage.getItem('token');
        const response = await VurService.searchVurFamily(propertyRecord, downloadParent, downloadChildren, token);
        if (response) {
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se enviaron correctamente los datos al sistema',
            alertType: 'success'
          }, {root: true});
          // asignar el id de la tarea de celery
          state.celeryTaskId = response.data.task_id;
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error generar consulta VUR en el sistema
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    },
    async completeVurFamily({ commit, state, rootState }, {propertyRecord, downloadParent, downloadChildren}) {
      /**
       * Acción para completar la tradicion del folio desde el VUR
       *
       * @param {propertyRecord} string El número del folio de matrícula inmobiliaria
       * @param {downloadParent} bool Cuando se vana descargar las matriculas matrices
       * @param {downloadChildren} bool Cuando se vana descargar las matriculas segregadas
       * @returns {} No retorna nada
       * @example
       * $store.dispatch('completeVurFamily', {
       *  propertyRecord: '070-26232',
       *  downloadParent: true,
       *  downloadChildren: false,
       * })
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      state.loadingVurFamily = true;
      state.vurFamilyLogs = [];

      // fijar el folio de consulta
      rootState.folios.graphFolioNumber = propertyRecord;
      // resetear el id de la tarea celery
      state.celeryTaskId = '';

      // Encontrar los padres e hijos que faltan para completar la familia
      let missingParent = rootState.folios.folioFamily.filter((x) => x.relation_type === 'PARENT' && x.avalaible === false);
      if (missingParent.length > 0) {
        missingParent = missingParent.map((x) => x.name);
      }
      let missingChildren = rootState.folios.folioFamily.filter((x) => x.relation_type === 'CHILD' && x.avalaible === false);
      if (missingChildren.length > 0) {
        missingChildren = missingChildren.map((x) => x.name);
      }
      try {
        const token = localStorage.getItem('token');
        const response = await VurService.completeVurFamily(propertyRecord, downloadParent,
          downloadChildren, missingChildren, missingParent, token);
        if (response) {
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se enviaron correctamente los datos al sistema',
            alertType: 'success'
          }, {root: true});
          // asignar el id de la tarea de celery
          state.celeryTaskId = response.data.task_id;
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error generar consulta VUR en el sistema
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    },
    async reBuildPdf({ commit, rootState}, {folioId}) {
      /**
       * Acción para reconstruir el PDF de un folio cuyos datos provienen del VUR
       *
       * @param {folioId} string El número del folio de matrícula inmobiliaria
       * @returns {} No retorna nada
       * @example
       * $store.dispatch('reBuildPdf', {
       *  propertyRecord: '5e8c9923-4a33-40ae-b530-ccd56056a709',
       * })
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      try {
        const token = localStorage.getItem('token');
        const response = await VurService.reBuildPdf(folioId, token);
        if (response) {
          console.log(response);
          rootState.folios.currentFolio.url_archivo = response.data.pdfPath;
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se creo el archivo de forma exitosa',
            alertType: 'success'
          }, {root: true});
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al tartar de reconstruir el PDF en el sistema
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
    },
    async taskTimeoutFinished({ commit, state}, { taskId, message, taskType }) {
      /**
       * Acción que maneja el evento en el cual el servidor finaliza la tarea por
       * que se ha superado el limite de tiempo
       *
       * @param {Object} contexto contexto de la acción
       * @param {Object} payload datos de la acción
       * @returns {} No retorna nada
       * @example
       * $store.dispatch('taskTimeoutFinished', {
       *  taskId: '5e8c9923-4a33-40ae-b530-ccd56056a709',
       *  message: 'mensaje de error',
       *  taskType: 'GET_VUR_FAMILY' | 'COMPLETE_VUR_FAMILY' | 'GET_VUR_ESTATES_OWNERS' | 'GET_VUR_CERTIFICATES'
       * })
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      // resetera el id de la tarea celery
      if (state.celeryTaskId === taskId) {
        state.celeryTaskId = '';
      }
      // mostrar alerta indicado del error
      commit('ui/SHOW_ALERT',{
        alertMessage: message,
        alertType: 'error'
      }, {root: true});
      // tomar las acciones según el tipo de tarea
      switch (taskType) {
        case 'GET_VUR_FAMILY':
          state.loadingVurFamily = false;
          break;
        case 'COMPLETE_VUR_FAMILY':
          state.loadingVurFamily = false;
          break;
        case 'GET_VUR_ESTATES_OWNERS':
          state.loadingVurOwners=false;
          // mostrar mensaje en la lista de carga
          commit('UPDATE_LIST_VUR_MESSAGE', {
            icon: 'mdi-bug',
            message
          });
          break;
        case 'GET_VUR_CERTIFICATES':
          // marcar con error los folios que no se han descargado
          commit('SET_ERROR_TIMEOUT', message);
          break;
        case 'MULTIPLE_GET_VUR_CERTIFICATES':
          state.loadingVur = false;
          // marcar con error los folios que no se han descargado
          commit('SET_ERROR_TIMEOUT', message);
          break;
      }
    }
  },

  mutations: {
    CLEAR_CERTIFICATES(state, type) {
      if (type === 'invalid') {
        state.certificates = state.certificates.filter((x) => x.valid === true && x.processed === false);
      }
      if (type === 'processed') {
        state.certificates = state.certificates.filter((x) => x.processed === false);
      }
    },
    WRITE_ERROR(state, errorFolios) {
      const folios = errorFolios.map((x) => x.record);
      state.excelError = `Los siguiente(s) números de matrícula inmobiliaria no serán cargados al sistema: <b>${folios.join(', ')}</b>`;
    },
    ADD_CERTIFICATE(state, {
      certificateId,
      certificateNumber,
      valid,
      identification,
      polygon,
      registerData,
      folioId,
      sources,
      matchDb,
      cadastralDbs,
      }) {
      const circle = state.registrationCircles.filter((x) => x.codigo === certificateNumber.slice(0,3))[0]
      const certificate = {
        circle: circle.nombre,
        certificate: certificateNumber,
        id: certificateId,
        valid,
        identification,
        polygon,
        registerData,
        processed: false,
        folioId,
        progress: 0,
        error: false,
        initProcess: null,
        endProcess: null,
        sources: sources,
        matchDb,
        cadastralDbs,
      };
      state.certificates.push(certificate);
      console.log(state.certificates);
    },
    DELETE_CERTIFICATE (state, id) {
      // eliminar el elemento seleccionado, si es igual al que se va a seleccionar
      if (state.selectedCertificate) {
        if (id === state.selectedCertificate.id) {
          state.selectedCertificate = null;
        }
      }
      state.certificates = state.certificates.filter(x => x.id !== id);
      // setear mensaje de la lista
      if (state.certificates.length === 0) {
        state.vurListIcon = 'mdi-file-document-remove';
        state.vurListMessage = 'Aún no hay certificados en la lista.';
      }
    },
    SELECT_CERTIFICATE (state, certificate) {
      state.selectedCertificate = certificate;
    },
    CLEAR_SEARCH(state) {
      state.excelError = '';
      state.certificates = [];
    },
    SET_CIRCLES(state, circles) {
      state.registrationCircles = circles;
    },
    UPDATE_CERTIFICATES(state) {
      const processDate = new Date();
      // TODO quitar de la lista los certificados que no estan validados
      // state.certificates = state.certificates.filter((x) => x.valid === true);
      // pasar los estados a procesado
      state.certificates.map((x) => {
        x.processed = true;
        x.initProcess = processDate;
      });
    },
    UPDATE_PROGRESS(state, {progress, message, certId}) {
      const processedCertificate = state.certificates.filter((x) => x.id === certId)[0];
      processedCertificate.progress = progress;
      processedCertificate.message = message;
    },
    FINISH_CERTIFICATE(state, {message, certId, folioId}) {
      const processDate = new Date();
      const processedCertificate = state.certificates.filter((x) => x.id === certId)[0];
      processedCertificate.progress = 100;
      processedCertificate.message = message;
      processedCertificate.ready = true;
      processedCertificate.folioId = folioId;
      processedCertificate.endProcess = processDate;
    },
    ERROR_CERTIFICATE(state, {certId, message}) {
      const processDate = new Date();
      const processedCertificate = state.certificates.filter((x) => x.id === certId)[0];
      processedCertificate.progress = 100;
      processedCertificate.endProcess = processDate;
      processedCertificate.error = true;
      processedCertificate.message = message;
    },
    // Mutacion para cambiar el contenido de la lista VurManager
    UPDATE_LIST_VUR_MESSAGE(state, {icon, message}) {
      console.log('Entro Mutacion', icon, message);
      state.vurListIcon = icon;
      state.vurListMessage = message;
    },
    // mutacion para establecer los valores de cualquier variable
    CHANGE_VALUE(state, {value, varName}) {
      state[varName] = value;
    },
    // mutación para notificar el exito en la descarga de la familia
    FINISH_VUR_FAMILY(state) {
      state.loadingVurFamily = false;
    },
    // mutación para notificar el error en la descarga de la familia
    ERROR_VUR_FAMILY(state) {
      state.loadingVurFamily = false;
    },
    // mutación para agregar un mensaj een la descar del VUR
    ADD_MESSAGE_LOG(state, {fmiData}) {
      // agregar elemento inicial del listado (separador folio)
      const relation = MAP_RELATIONS[fmiData.relation]
      if ('fmi' in fmiData && 'relation' in fmiData) {
        state.vurFamilyLogs.push({
          header: true,
          title: fmiData.fmi,
          subtitle: relation
        });
      }
      // agregar información de folio truncado
      if ('truncated' in fmiData) {
        if(fmiData.truncated.length > 0) {
          state.vurFamilyLogs.push({
            title: `Se truncaron un total de ${fmiData.truncated.length}`,
            subtitle: `Folios truncados(CONTACTE AL ADMINSTRADOR PARA DESCARGA): ${fmiData.truncated.join(', ')}`,
            success: true
          });
        }
      }

      // agregar información del resultado en aplicación
      state.vurFamilyLogs.push({
        title: fmiData.title,
        subtitle: fmiData.subtitle,
        success: fmiData.success
      });
    },
    // Mutación para indicar que ya se ha descargado el soporte por búsqueda VUR
    SUPPORT_VUR_FILE(state, {supportType, path}) {
      // agregar el soiporte de busqueda por Nombres
      if (supportType === 'namesSearch') {
        state.nameSupportUrl = `${staticScrapperUrl}/${path}`;
      }
      // agregar el soiporte de busqueda por identificacion
      if (supportType === 'identificationSearch') {
        state.identificationSupportUrl = `${staticScrapperUrl}/${path}`;
      }
    },
    // Detener carga VUR propietarios
    STOP_LOADING_VUR_OWNERS(state){
      state.loadingVurOwners = false;
    },
    // Mutación para indicar error 
    ERROR_VUR_OWNER(state, {error}) {
      state.vurListIcon = 'mdi-bug';
      state.vurListMessage = error;
      state.loadingVurOwners = false;
    },
    SET_ERROR_TIMEOUT(state, error) {
      /**
       * Mutación para marcar con error todos los folios que no han sido
       * descargados cuando la tarea asincrona finaliza abruptamnete
       *
       * @param {Object} state variable apara acceder a los estados del modulo
       * @param {String} error mensaje del error para colocar en el folio
       * @returns {} No retorna nada
       * @example
       * $store.commit('SET_ERROR_TIMEOUT', 'mesaje de error')
       * @author Luis Merizalde <luis.merizalde@arcerojas.com>
       */
      const processDate = new Date();
      // obtener los certificados que no se han terminado
      const filteredCertificates = state.certificates.filter((x) => x.progress < 100);
      for (let index = 0; index < filteredCertificates.length; index++) {
        const certificate = filteredCertificates[index];
        certificate.progress = 100;
        certificate.endProcess = processDate;
        certificate.error = true;
        certificate.message = error;
      }
    },
    // mutación para notificar el exito en la descarga de la familia
    FINISH_VUR_DOWNLOAD(state) {
      state.loadingVur = false;
    },
    // mutacion para resetear los folios que no se han inciado a descargar
    RESTART_NO_DOWNLOADED_CERTIFICATES(state) {
      // filtra porcentajes menor a 40 ya que en 40 el procesamineto del folio ya no esta
      // en la aplicacion scrapper
      const filteredCertificates = state.certificates.filter((x) => x.progress < 40);
      for (let index = 0; index < filteredCertificates.length; index++) {
        const certificate = filteredCertificates[index];
        certificate.processed = false;
        certificate.initProcess = null;
        certificate.message = '';
      }
    },
  },
  getters: {
    validCertificates: (state) => state.certificates.filter((x) => x.valid === true && x.processed === false).length,
    processedCertificates: (state) => state.certificates.filter((x) => x.processed === true).length,
    invalidCertificates: (state) => state.certificates.filter((x) => x.valid === false && x.processed === false).length,
    certificateExists: (state) => (certificateNumber) => {
      const filteredCertificates = state.certificates.filter((x) => x.certificate ==certificateNumber);
      return filteredCertificates.length > 0 ? true : false;
    }
  }
};
  
export default vur;