import { mapMutations } from 'vuex';
import { staticUrl } from '@/env';
import { firstLimitDaysFolio, secondLimitDaysFolio } from '@/config';
import axios from 'axios';

export default {
  data() {
    return {
    };
  },

  methods: {
    ...mapMutations('ui', [
      'TOGGLE_SIDE_DIALOG',
      'SET_SIDE_DIALOG_CONTENT',
      'SET_SIDE_DIALOG_TITLE',
      'SET_SIDE_DIALOG_WIDTH',
      'CHANGE_VALUE',
    ]),
    detailFolio(folioId) {
      this.$router.push({
        name: 'FolioDetail',
        params: {folioId}
      });
      // cerrar el modal
      this.$store.dispatch('ui/toggleDialog', false);
    },
    // abrir folio en una ventana nueva
    targetFolio(folioId) {
      this.$store.dispatch('ui/toggleDialog', false);
      const routeData = this.$router.resolve({
        name: 'FolioDetail',
        params: {folioId}
      });
      window.open(routeData.href, '_blank');
    },
    analysisFolio(folioId) {
      this.$router.push({
        name: 'FolioAnalysis',
        params: {folioId}
      });
      // cerrar el modal
      this.$store.dispatch('ui/toggleDialog', false);
    },
    // mostrar PDF del folio en un modal
    showPdf(url) {
      this.$bus.$emit('update-properties', {
        urlFile: `${staticUrl}${url}`
      });
      this.$store.commit('ui/SET_DIALOG_CONTENT', 'PdfPreview');
      this.$store.commit('ui/SET_DIALOG_TITLE', 'Archivo Folio');
      this.CHANGE_VALUE({
        varName: 'modalWidth',
        value: '100%',
      });
      this.$store.commit('ui/TOGGLE_DIALOG', true);
    },
    // mostrar PDF de una url completa
    showEntirePdf(url, title) {
      this.$bus.$emit('update-properties', {
        urlFile: url
      });
      this.$store.commit('ui/SET_DIALOG_CONTENT', 'PdfPreview');
      this.$store.commit('ui/SET_DIALOG_TITLE', title);
      this.$store.commit('ui/TOGGLE_DIALOG', true);
    },
    // mostrar imagen en modal
    showModalImage(url, title) {
      this.$bus.$emit('update-properties', {
        urlFile: url
      });
      this.$store.commit('ui/SET_DIALOG_CONTENT', 'ImagePreview');
      this.$store.commit('ui/SET_DIALOG_TITLE', title);
      this.CHANGE_VALUE({
        varName: 'modalWidth',
        value: '100%',
      });
      this.$store.commit('ui/TOGGLE_DIALOG', true);
    },
    getStatus: function (date) {
      /*
      Funcion para establecer el color de fondo de una fila según 
      la fecha de impresión del folio
      */
      const printedDay = new Date(date);
      const today = new Date();
      let difference = today.getTime() - printedDay.getTime();
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      let info = {
        color: 'red',
        title: 'Folio no apto para estudio titulos',
        message: `Fecha Impresión > ${secondLimitDaysFolio} días`
      };
      if (totalDays < firstLimitDaysFolio) {
        info.color = 'green';
        info.title = 'Folio Apto para estudio titulos';
        info.message = `Fecha Impresión < ${firstLimitDaysFolio} días`;
      }
      if (totalDays > firstLimitDaysFolio && totalDays <= secondLimitDaysFolio) {
        info.color = 'yellow';
        info.title = 'Revisar aptitud';
        info.message = `Fecha Impresión entre ${firstLimitDaysFolio} y ${secondLimitDaysFolio} días días`;
      }
      return info;
    },
    // metodo para ir a la vista de familia registral de este folio
    generateFamily() {
      const routeData = this.$router.resolve({
        name: 'Family',
        query: {
          fmi: this.currentFolio.numero_matricula
        }
      });
      window.open(routeData.href, '_blank');
    },
    // metodo para inicializar el VUR con una matricula
    initializeVur(folioNumber) {
      const routeData = this.$router.resolve({
        name: 'Vur',
        query: {
          fmi: folioNumber
        }
      });
      window.open(routeData.href, '_blank');
    },
    // metodo realizar la busqueda en la base catastral
    cadastralSearch(param, number) {
      const query = {}
      query[param] = number;
      const routeData = this.$router.resolve({
        name: 'Search',
        query
      });
      window.open(routeData.href, '_blank');
    },
    // metodo para cargar una lista de folios en el la busqueda masiva
    loadVurRecords(folioList) {
      const routeData = this.$router.resolve({
        name: 'VurMassiveSearch',
        query: {
          folios: JSON.stringify(folioList),
          preserveList: true,
        },
      });
      window.open(routeData.href, '_blank');
    },
    // metodo para mostrar la linea de tiempo de las anotaciones
    showTimeline() {
      this.SET_SIDE_DIALOG_CONTENT('AnnotationTimeline');
      this.SET_SIDE_DIALOG_WIDTH('30vw');
      this.SET_SIDE_DIALOG_TITLE('Anotaciones (ordenadas por fecha documento)');
      this.TOGGLE_SIDE_DIALOG(true);
    },
    // metodo para verificar si el pdf de un filio existe
    async checkFileExists(url) {
      let fileExists = false;
      try {
        const response = await axios.head(url);
        if (response.status === 200) {
          fileExists = true;
        }
        console.log(response.status === 200 ? 'File exists' : 'File does not exist');
      } catch (error) {
        console.log('File does not exist');
      }
      return fileExists;
    }
  }
};
