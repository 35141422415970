<template lang="pug">
div
  v-container(fluid v-if="isMounted")
    v-alert(
      class="text-h4"
      border="bottom"
      color="blue-grey"
      dark)
        | Análisis Jurídico del Folio
    v-btn(
      color="info"
      class="mb-2 white--text"
      @click="detailFolio(currentFolio.id)") Ver detalle folio
      v-icon(
        right
        dark) mdi-information
    v-row(dense)
      v-col(cols="12")
        folio-main-info

    v-row
      v-col(cols="12")
        folio-owner-analysis

    v-row
      v-col(cols="12")
        folio-record-one

    v-row
      v-col(cols="12")
        folio-annotations-analysis

    folio-tools
  loading-content(v-else)
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import FolioMainInfo from '@/components/folios/FolioMainInfo.vue';
import FolioOwnerAnalysis from '@/components/folios/FolioOwnerAnalysis.vue';
import FolioAnnotationsAnalysis from '@/components/folios/FolioAnnotationsAnalysis.vue';
import FolioRecordOne from '@/components/folios/FolioRecordOne.vue';
import folioMixin from '@/mixins/folioMixin.js';
import LoadingContent from '@/components/ui/LoadingContent.vue';
import FolioTools from '@/components/folios/FolioTools.vue';

export default {
  name: 'FolioAnalysis',

  components: {
    FolioMainInfo,
    FolioAnnotationsAnalysis,
    FolioRecordOne,
    FolioOwnerAnalysis,
    LoadingContent,
    FolioTools,
  },

  mixins: [folioMixin],

  data: () => ({
    isMounted: false,
  }),

  computed: {
    ...mapState('folios', ['currentFolio', 'viewedAnnotation']),
  },

  watch: {
    async $route(to) {
      const { folioId } = to.params;
      this.SET_ANNOTATION(null);
      await this.getFolioDetail(folioId);
    }
  },

  async mounted() {
    const { folioId } = this.$route.params;
    await this.SET_ANNOTATION(null);
    await this.getFolioDetail(folioId);
    this.SELECT_ANNOTATION(null);
    this.isMounted = true;
  },

  methods: {
    ...mapActions('folios', ['getFolioDetail']),
    ...mapMutations('folios', ['SET_ANNOTATION', 'SELECT_ANNOTATION']),
  }
};
</script>