<template lang="pug">
v-chip(
  v-if="isMounted"
  :title="title"
  :class="['mr-2', numberCancellation !== 0 ? 'is-cancel' : '']"
  small
  @click.stop="seeAnnotation(annotationNumber)"
  :color="colorBadge"
  :text-color="colorBadge === 'yellow lighten-2' ? 'black' : 'white'") <b>{{formatNumber(annotationNumber)}}</b>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'AnnotationBadge',

  props: {
    annotationNumber: {
      type: Number,
      required: true
    },
    // propiedad para ver información de anotacion en modal
    showAnnotation: {
      type: Boolean,
      default: false
    },
    numberCancellation: {
      type: Number,
      default: 0
    },
    isCancellation: {
      type: Boolean,
      default: false
    },
    // enviar los datos completos de la anotación cuando el badge se utiliza
    // por fuera de la vista del detalle del folio
    annotation: {
      type: Object,
      default: () => {}
    },
    // enviar el folio al que pertenece la anotacion cuando esta por fuera
    // de la vista de detalle del folio
    folioNumber: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    currentAnnotation: null,
    especificationCode: null,
    especification: null,
    isMounted: false,
  }),

  computed: {
    ...mapState('folios', ['currentFolio']),
    colorBadge() {
      return this.getColor(this.especificationCode, this.annotationNumber);
    },
    title() {
      let title = 'Número anotación';
      
      if (this.numberCancellation !== 0) {
        title = `Cancelada por anotacion (${String(this.numberCancellation).padStart(3, '0')})`;
      }
      if (this.showAnnotation === true) {
        title = 'Desplegar anotación';
      }
      return title;
    }
  },

  mounted() {
    this.getAnnotation();
    this.isMounted = true;
  },

  updated() {
    this.getAnnotation();
  },

  methods: {
    ...mapMutations('ui', [
      'SET_DIALOG_CONTENT',
      'SET_DIALOG_TITLE',
      'TOGGLE_DIALOG',
      'CHANGE_VALUE',
    ]),
    ...mapMutations('folios', ['SET_ANNOTATION']),
    seeAnnotation() {
      let folioMode = true;
      if (this.showAnnotation === false) {
        return;
      }
      if (this.annotationNumber === 0) {
        return;
      }
      
      if (this.currentFolio !== null) {
        this.currentAnnotation = this.currentFolio.anotaciones.filter(
        (x) => x.numero === this.annotationNumber)[0];
      }
      
      if (this.annotation) {
        folioMode = false;
      }
      this.SET_DIALOG_CONTENT('AnnotationDetail');
      if (folioMode) {        
        this.SET_DIALOG_TITLE('Información de la anotación');
      } else {
        this.SET_ANNOTATION(null);
        this.SET_DIALOG_TITLE(`Información de la anotación ${this.currentAnnotation.numero} del folio ${this.folioNumber}`);
      }
      this.SET_ANNOTATION(this.currentAnnotation);
      this.CHANGE_VALUE({
        varName: 'modalWidth',
        value: '100%',
      });
      this.TOGGLE_DIALOG(true);
    },
    getAnnotation() {
      if (this.annotation) {
        this.currentAnnotation = this.annotation;
      }
      if (this.annotationNumber === 0) {
        return;
      }
      if (this.currentFolio !== null) {
        this.currentAnnotation = this.currentFolio.anotaciones.filter(
          (x) => x.numero === this.annotationNumber)[0];
      }      
      if (this.currentAnnotation) {
        this.especificationCode = this.currentAnnotation.codigo_especificacion;
        this.especification = this.currentAnnotation.especificacion;
      }      
    },
    formatNumber(number) {
      if (number === 0) {
        return 'Folio'
      }
      return String(number).padStart(3, '0');
    },
    
    getColor(code, number) {  
      if (number === 0) {
        return 'grey darken-1';
      }
      try {
        code = code.replaceAll('0', '')[0];
      } catch (error) {
        return 'lime darken-3';
      }
      
      let color = ''
      switch (code) {
        // TRADICION
        case '1':
          color = 'light-green';
          break;
        // MEDIDAS CAUTELARES
        case '4':
          color = 'red';
          break;
        // LIMITACIONES Y AFECTACIONES
        case '3':
          color = 'orange';
          break;
        // CANCELACIONES
        case '7':
          color = 'yellow lighten-2';
          break;
        case '8':
          color = 'yellow lighten-2';
          break;
        // OTROS
        case '9':
          color = 'blue-grey lighten-1';
          break;
        // GRAVAMENES
        case '2':
          color = 'amber';
          break;  
        // FALSA TRADICION
        case '6':
          color = 'pink darken-2';
          break;
        // TENENCIA
        case '5':
          color = 'purple darken-1';
          break;
        default:
          color = 'brown darken-1';
          break;
      }
      return color;
    }
  }
};
</script>

<style scoped>
.is-cancel:after {
  position: absolute;
  content: "\2715"; /* \274c 2717 */
  font-size: 20px; 
  font-weight: 100;
  color: black;
  text-align: center;
  transform: scale(2, 1.3);
}
</style>