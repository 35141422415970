<template lang="pug">
v-form(ref="form")
  v-container
    v-row
      v-col(
        cols="12"
        md="9")
        fmi-picker(v-model="propertyRecord" ref="fmiNumber")

      v-col(
        cols="12"
        md="3")
        v-btn(
          :loading="isValidating"
          :disabled="certificates.length >= maxQueryCertificates"
          color="green"
          class="ma-2 white--text"
          @click="addCertificate")
          | Agregar
          v-icon(
            right
            dark) mdi-plus-circle-outline
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { maxQueryCertificates } from '@/config';
import FmiPicker from '@/components/ui/FmiPicker.vue';
export default {
  name: 'VurBrowser',

  components: {    
    FmiPicker,     
  },

  data: () => ({
    maxQueryCertificates,
    loading: false,
    propertyRecord: '',
  }),  

  computed: {
    ...mapState('vur', ['registrationCircles', 'isValidating', 'certificates']),
    ...mapGetters('vur', ['certificateExists'])
  },

  mounted() {
    if ('fmi' in this.$route.query) {
      // Inicializar el numero de matricula inmobiliaria
      const { fmi } = this.$route.query;
      const [circle, number] = fmi.split('-')
      this.$refs.fmiNumber.selectedCircle = circle;
      this.$refs.fmiNumber.recordText = number;
      this.$refs.fmiNumber.valid = true;
      this.propertyRecord = fmi;
      // eliminar el parametro de la ruta
      window.history.replaceState({}, document.title, "/" + "vur");
    }
  },

  methods: {
    ...mapMutations('ui', ['SHOW_ALERT']),
    ...mapActions('vur', ['validateCertificate']),
    getCircle(e) {
      return `${e.codigo} - ${e.nombre}`
    },
    addCertificate() {
      if (!this.$refs.fmiNumber.validate()) {
        alert('Debe introducir un número de matrícula válido.')
        return
      }
      if (this.certificateExists(this.propertyRecord)) {
        this.SHOW_ALERT({
          alertMessage: `La matrícula ${this.propertyRecord} ya se encuentra en la lista`,
          alertType: 'error'
        });
        return;
      }
      this.validateCertificate(this.propertyRecord);
    },
    validate() {
      return this.$refs.form.validate();
    },
  }
}
</script>