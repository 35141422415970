<template lang="pug">
v-card(
  class="mx-auto px-3"
  max-width="70%")
  v-list-item(two-line)
    v-list-item-content
      v-list-item-title(class="text-h5") Consultar Certificados de Tradición y Libertad
      v-list-item-subtitle
        | Para consultar los Fmi relacionados a una persina natural o jurídica
      v-list-item-subtitle
        | Ingrese los datos de Nombre y/o Identificación

  identification-preview(
    card-title="CONSULTA VUR PERSONAS"
    :show-expedition-date="false")
  
  v-btn(
    :loading="loadingVurOwners"
    v-if="hasPermission('can_use_vur')"
    color="green"
    title="Buscar propietario en el VUR"
    small
    class="ma-2 white--text"
    @click="makeSearch")
    | Consultar propietario
    v-icon(
      right
      dark) mdi-account-search
  v-btn(v-else class="white--text" color="blue" @click="$router.push('/vur-credentials')")
    | Ingresar credenciales
    v-icon(right dark) mdi-arrow-right-bold
  //- Botones descarga soportes VUR
  v-btn(
    v-if="identificationSupportUrl !== ''"
    title="Ver Soporte Búsqueda"
    color="orange"
    small
    class="ma-2 white--text gelatine"
    @click="downloadSupportId()")
    v-icon(left dark) mdi-file-download
    | Soporte Identificación Búsqueda por Identificación
  v-btn(
    v-if="nameSupportUrl !== ''"
    title="Ver Soporte Búsqueda"
    color="orange"
    small
    class="ma-2 white--text gelatine"
    @click="downloadSupportName()")
    v-icon(left dark) mdi-file-download
    | Soporte Búsqueda por Nombres
  v-btn(
    :loading="loadingVurOwners"
    color="red"
    title="limpiar datos de sujeto y folios"
    small
    class="ma-2 white--text"
    @click="clear")
    v-icon(dark) mdi-broom
    | Limpiar

  v-divider

  div(class="d-flex flex-row")
    v-list-item
      v-list-item-icon
        v-icon(color="green") mdi-check-circle
      v-list-item-subtitle <b>{{ validCertificates }}</b> Válido(s)

    v-list-item
      v-list-item-icon
        v-icon(color="yellow" style="background: black; border-radius: 20px;") mdi-alert-circle
      v-list-item-subtitle <b>{{ invalidCertificates }}</b> No verificado(s)

    v-list-item
      v-list-item-icon
        v-icon(color="blue") mdi-database-check
      v-list-item-subtitle <b>{{ processedCertificates }}</b> Procesados(s)

  certificates-list
  v-divider

  v-card-actions
    //- Boton para cancelar la descarga de predios propietario
    v-btn(
      class="white--text"
      :loading="loadingCancel"
      :disabled="loadingCancel"
      v-if="celeryTaskId !== ''"
      class="ma-2"
      color="red"
      @click="actionCancel")
      v-icon(class="mr-1" dark) mdi-cancel
      | Cancelar
    v-spacer
    //- TODO certificados validos
      v-btn(
        v-if="hasPermission('can_use_vur')"
        :disabled="!validCertificates > 0"
        @click="sendCertificates"
        class="white--text"
        color='green') Consultar
    v-btn(
      v-if="certificates.length > 0"
      color="green"
      class="ma-2 white--text"
      title="Descargar Excel con información folios"
      @click="downloadExcel")
      v-icon(left dark) mdi-file-excel
      | Descargar Excel
    v-btn(
      v-if="hasPermission('can_use_vur')"
      :disabled="(validCertificates + invalidCertificates) < 1"
      @click="sendCertificates"
      class="white--text"
      color='green') Descargar folio(s) VUR 
    v-btn(v-else class="white--text" color="blue" @click="$router.push('/vur-credentials')")
      | Ingresar credenciales
      v-icon(right dark) mdi-arrow-right-bold
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import folioMixin from '@/mixins/folioMixin.js';
import celeryMixin from '@/mixins/celeryMixin.js';
import VurBrowser from '@/components/vur/VurBrowser';
import CertificatesList from '@/components/vur/CertificatesList';
import { maxQueryCertificates } from '@/config.js';
import PropertyVisor from '@/components/PropertyVisor';
import IdentificationPreview from '@/components/restrictive_lists/IdentificationPreview.vue';
import * as XLSX from 'xlsx';


export default {
  name: 'VurOwnerManager',

  components: {
    CertificatesList,
    VurBrowser,
    PropertyVisor,
    IdentificationPreview
  },

  mixins: [celeryMixin, folioMixin],

  data: () => ({
    labels: [],
    time: 0,
    downloadUrl: '',
    maxQueryCertificates
  }),

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('vur', [
      'certificates',
      'selectedCertificate',
      'loadingVurOwners',
      'identificationSupportUrl',
      'nameSupportUrl',
      'celeryTaskId',
    ]),
    ...mapState('restrictions', ['targetData', 'naturalPerson']),
    ...mapGetters('vur', ['validCertificates', 'invalidCertificates', 'processedCertificates']),
    
  },

  mounted() {
    // remover los tipos de identificacion que no aplican en el VUR
    this.SET_VUR_IDS_TYPES();
    // traer los circulos registrales para poder crear los folios
    this.getCircles();
  },

  methods: {
    ...mapMutations('ui', ['SHOW_ALERT']),
    ...mapMutations('vur', ['CHANGE_VALUE']),
    ...mapMutations('restrictions', ['SET_VUR_IDS_TYPES', 'RESTART_SUBJECT']),
    ...mapActions('vur', [
      'getCircles',
      'sendVurPerson',
      'uploadCertificates',
    ]),
    makeSearch() {      
      // validar criterios cuando de consulta por persona juridica
      if (this.targetData.firstName === '' && this.targetData.identification === '' && !this.naturalPerson) {
        alert('Debe introducir la razón social de la empresa o la identificación');
        return;
      }

      // validar criterios cuando de consulta por persona natural
      if ((this.targetData.firstName === '' || this.targetData.firstSurname === '')
        && this.naturalPerson && this.targetData.identification === '') {
        alert('Debe introducir el primer nombre, el primer apellido o la identificación');
        return;
      }
      this.sendVurPerson();
    },
    sendCertificates() {
      if ((this.validCertificates + this.invalidCertificates) === 0) {
        this.SHOW_ALERT({
          alertMessage: 'Debe haber por lo menos un certificado para enviar',
          alertType: 'error'
        });
        return;
      }
      this.uploadCertificates(true);
    },
    downloadSupportId() {
      if (this.identificationSupportUrl.includes('.png') || this.identificationSupportUrl.includes('.jpg')) {
        this.showModalImage(
          this.identificationSupportUrl,
          'Soporte Búsqueda por Identificación'
          );
      } else {
        this.showEntirePdf(this.identificationSupportUrl, 'Soporte Búsqueda por Identificación');
      }
    },
    downloadSupportName() {
      if (this.nameSupportUrl.includes('.png') || this.nameSupportUrl.includes('.jpg')) {
        this.showModalImage(this.nameSupportUrl, 'Soporte Búsqueda por Nombre');
      } else {
        this.showEntirePdf(this.nameSupportUrl, 'Soporte Búsqueda por Nombre');
      }
    },
    // metodo para descargar excel con la información de la familia
    downloadExcel() {
      const data = []
      for (let index = 0; index < this.certificates.length; index++) {
        const element = this.certificates[index];
        data.push([
          element.certificate,
          element.sources.join(', ')
        ]);
      }
      let dateString = new Date();
      dateString = dateString.toISOString().replace('T','_')
        .replace('Z','').replaceAll('-','_').replace(':','_').replace('.','_')
      // Crear una nueva hoja de cálculo
      const ws = XLSX.utils.aoa_to_sheet(data);

      // Crear un nuevo libro de trabajo
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Folios");

      // Generar y descargar el archivo Excel
      XLSX.writeFile(wb, `certificados_propietario_${dateString}.xlsx`);
    },
    // metodo para limpirar los datos de la busqueda
    clear() {
      // limpiar los datos de la tarjeta de identificacion
      this.RESTART_SUBJECT();
      // limpiar los certificados
      this.CHANGE_VALUE({
        varName: 'certificates',
        value: []
      });
      // limpiar los soportes
      this.CHANGE_VALUE({
        varName: 'identificationSupportUrl',
        value: ''
      });
      this.CHANGE_VALUE({
        varName: 'nameSupportUrl',
        value: ''
      });
      // liampiar el mesaje de la lista
      this.CHANGE_VALUE({
        varName: 'vurListIcon',
        value: 'mdi-file-document-remove'
      });
      this.CHANGE_VALUE({
        varName: 'vurListMessage',
        value: 'Aún no hay certificados en la lista.'
      });
    }
  }
};
</script>
