<template lang="pug">
v-card(height="auto")
  v-toolbar(
    height="40"
    :color="folioColor"
    dark)
    v-toolbar-title Información general de las anotaciones del folio
  v-card-title(class="text-h5")
    | Se procesaron {{currentFolio.anotaciones.length}} de {{currentFolio.numero_anotaciones}} Anotaciones
  v-row(class="px-4")
    v-col(cols="6")
      annotation-list(:annotations="currentFolio.anotaciones")
    v-divider(vertical)
    v-col(cols="6")
      annotation-detail(        
        :annotation="annotation"
        v-if="annotation")
      div(class="text-center" v-else)
        div(class="text-h6")
          | Seleccione una anotación en el panel izquierdo para ver el detalle
    
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AnnotationList from '@/components/annotations/AnnotationList';
import AnnotationDetail from '@/components/annotations/AnnotationDetail';

export default {
  name: 'FolioAnnotationsInfo',
  components: {
    AnnotationList,
    AnnotationDetail,
  },
  computed: {
    ...mapGetters('folios', ['folioColor']),
    ...mapState('folios', ['currentFolio', 'viewedAnnotation']),
  },
  created() {
    // Evento para seleccionar anotación
    this.$bus.$on('select-annotation', (annotation) => {
      this.annotation = annotation.annotation;
    });
    // Evento para limpiar la anotación cuando se cambia de folio
    this.$bus.$on('clear-annotation', () => {
      this.annotation = null;
    });
  },
  data: () => ({
    annotation: null
  }),
}
</script>
