<template lang="pug">
div
  //- v-btn(
    v-if="hasPermission('can_add_folio')"
    color="info"
    class="ma-2 white--text"
    @click="$router.push({name: 'FoliosUpload'})") Cargar Folios
    v-icon(
      right
      dark) mdi-cloud-upload
  div
    v-card(style="position: fixed; z-index: 100; width: 99%")
      v-card-title CONVENCIONES
      list-convention-icon(class="ml-4" :conventions="conventions")
  div(style="padding-top: 150px")
    v-card(class="mt-2")
      v-card-title
        v-text-field(
          ref="inputSearch"
          v-model="search"
          :append-icon="loadingFolios ? 'mdi-timer-sand-complete' : 'mdi-magnify'"
          label="Buscar folios"
          single-line
          @input="debounceInput"
          hide-details)
      v-data-table(
        :headers="headers"
        :items="items"
        :loading="loadingFolios"
        loading-text="Cargando folios... Por favor espere"
        hide-default-footer
        :page.sync="page"
        disable-pagination)
        template(v-slot:item.id="{ item }")
          b {{items.map(function(x) {return x.id; }).indexOf(item.id) + (pageSize * (page - 1) + 1)}}
        template(v-slot:item.fecha_impresion="{ item }")
          | {{item.fecha_impresion.slice(0, 10)}}
        template(v-slot:item.numero_matricula="{ item }")
          span(v-if="item.errors")
            v-icon(
              class="mr-2 hithere"
              title="Folio con errores registrales"
              color="red accent-4") mdi-alert
          span(v-if="item.transfer_info")
            v-icon(
              v-if="item.transfer_info.length > 0"
              class="mr-2"
              :title="getTranferInfo(item.transfer_info)"
              color="indigo") mdi-file-swap
          v-icon(
            v-if="item.es_vur === true"
            class="mr-2"
            title="Datos provenientes VUR"
            color="red darken-2") mdi-database-search
          v-icon(
            v-if="item.geom === true"
            class="mr-2"
            title="Predio georreferenciado"
            color="orange") mdi-map-marker-radius
          v-icon(
            :set="info=getStatus(item.fecha_impresion)"
            :color="info.color"
            :title="info.title") mdi-traffic-light
          a(@click="detailFolio(item.id)") {{item.numero_matricula}}
          
        template(v-slot:item.fecha_carga="{ item }")
          | {{item.fecha_carga.slice(0, 10)}}
        template(v-slot:item.options="{ item }")
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn(
                color="gray"
                icon
                @click="showFolio(item.id)"
                v-bind="attrs"
                v-on="on")
                v-icon mdi-eye
            span Ver resumen diagnóstico
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn(
                color="gray"
                icon
                @click="analysisFolio(item.id)"
                v-bind="attrs"
                v-on="on")
                v-icon mdi-scale-balance
            span Ver análisis jurídico
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn(
                color="gray"
                icon
                @click="detailFolio(item.id)"
                v-bind="attrs"
                v-on="on"
                title="Ver detalle folio")
                v-icon mdi-information
            span Ver detalle folio
          v-tooltip(top)
            template(v-slot:activator="{ on, attrs }")
              v-btn(
                color="gray"
                icon
                @click="showPdf(item.url_archivo)"
                v-bind="attrs"
                v-on="on")
                v-icon mdi-file-pdf-box
            span Ver PDf del folio
      v-divider
      v-row(class="mx-5 mt-2")
        v-col(cols="4" sm="3")
          v-select(
            v-model="pageSize"
            :items="pageSizes"
            label="Items per Page"
            @change="handlePageSizeChange")
        v-spacer
        v-col(cols="12" sm="9")
          v-pagination(
            v-model="page"
            :length="totalPages"
            total-visible="7"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
            @input="handlePageChange")
</template>

<script>
import {mapState, mapMutations, mapActions, mapGetters} from 'vuex';
import folioMixin from '@/mixins/folioMixin.js';
import { firstLimitDaysFolio, secondLimitDaysFolio } from '../config';
import ListConventionIcon from '@/components/ui/ListConventionIcon';
import debounce from 'debounce';

export default {
  name: 'FoliosList',

  components: {
    ListConventionIcon,
  },

  mixins: [folioMixin],

  data: () => ({
    page: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50],
    search: '',
    conventions: [
      {
        icon: 'mdi-database-search',
        color: 'red darken-2',
        text: 'Datos provenientes VUR'
      },
      {
        icon: 'mdi-alert',
        color: 'red accent-4',
        text: 'ERROR(ES) REGISTRAL(ES)'
      },
      {
        icon: 'mdi-file-swap',
        color: 'indigo',
        text: 'Folio Trasladado'
      },
      {
        icon: 'mdi-map-marker-radius',
        color: 'orange',
        text: 'Predio georreferenciado'
      },
      {
        icon: 'mdi-traffic-light',
        color: 'green',
        text: `Impresión < ${firstLimitDaysFolio} días`
      },
      {
        icon: 'mdi-traffic-light',
        color: 'yellow',
        text: `Impresión entre ${firstLimitDaysFolio} y ${secondLimitDaysFolio} días`
      },
      {
        icon: 'mdi-traffic-light',
        color: 'red',
        text: `Impresión > ${secondLimitDaysFolio} días`
      },
    ],
    headers: [
      {
        text: '#',
        align: 'start',
        filterable: false,
        value: 'id',
      },
      { 
        text: 'Número matrícula inmobiliaria',
        value: 'numero_matricula',
        align: 'left'
      },
      { 
        text: 'Fecha impresión',
        value: 'fecha_impresion',
        align: 'center'
      },
      { 
        text: 'Circulo',
        value: 'nombre_circulo'
      },
      {
        text: 'Departamento',
        value: 'departamento',
        align: 'center',
        sortable: false
      },
      { 
        text: 'Municipio',
        value: 'municipio_txt',
        align: 'center'
      },
      {
        text: 'Nupre',
        value: 'npn',
        align: 'center'
      },
      {
        text: 'Total Anotaciones',
        value: 'numero_anotaciones',
        align: 'center'
      },
      { 
        text: 'Fecha de Apertura',
        value: 'fecha_apertura'
      },
      { 
        text: 'Usuario carga',
        value: 'usuario_carga'
      },
      { 
        text: 'Fecha carga',
        value: 'fecha_carga'
      },
      { 
        text:
        'Opciones',
        value: 'options'
      },
    ],
  }),

  computed: {
    ...mapState('folios', ['items', 'totalPages', 'loadingFolios']),
    ...mapGetters('auth', ['hasPermission']),
  },

  async mounted() {
    await this.getFolios({
      pageSize: this.pageSize,
      page: this.page,
      searchQuery: this.search
    });
  },

  methods: {
    ...mapMutations('ui', [
      'TOGGLE_DIALOG',
      'SET_DIALOG_CONTENT',
      'SET_DIALOG_TITLE',
      'CHANGE_VALUE',
    ]),
    ...mapActions('folios', ['getFolios', 'getFolioDetail']),
    debounceInput: debounce(async function () {
      this.page = 1;
      await this.getFolios({
        pageSize: this.pageSize,
        page: this.page,
        searchQuery: this.search
      });
      this.$refs.inputSearch.focus();
    }, 500),
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getFolios({
        pageSize: this.pageSize,
        page: this.page,
        searchQuery: this.search
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.getFolios({
        pageSize: this.pageSize,
        page: this.page,
        searchQuery: this.search
      });
    },
    getLegalColor(status) {
      let color = '';
      switch(status) {
        case 'good':
          color='green darken-1';
          break;
        case 'middle':
          color='yellow darken-1';
          break;
        case 'bad':
          color='red darken-1';
          break;
      }
      return color;
    },
    async showFolio(folioId) {
      await this.getFolioDetail(folioId);
      this.SET_DIALOG_CONTENT('FolioSummary');
      this.SET_DIALOG_TITLE('Información Folio');
      this.CHANGE_VALUE({
        varName: 'modalWidth',
        value: '500',
      });
      this.TOGGLE_DIALOG(true);
    },
    getTranferInfo(items) {
      /*
      Funcion para devolver el texto de los folios trasladados
      */
      return `Matrículas previas: ${items.map((x) => x.matricula_origen).join(' ')}`
    }
  },
   
};
</script>
