import { mapActions, mapState, mapMutations } from 'vuex';

export default {
  data() {
    return {
      // propiedades para manejar la carga de usuarios
      loadingCities: false,
      cities: [],
      searchCities: '',
    };
  },

  computed: {
    ...mapState('cadastral', ['filteredCities']),
    ...mapState('ui', ['modalWidth']),
  },

  watch: {
    async searchCities(val) {
      await this.getCities(val, '');
      this.cities = this.filteredCities
    },
  },

  methods: {
    ...mapActions('cadastral', ['getCities']),
    ...mapMutations('ui', {
      uiToggleDialog: 'TOGGLE_DIALOG',
      uiSetDialogContent: 'SET_DIALOG_CONTENT',
      uiSetDialogTitle: 'SET_DIALOG_TITLE',
      uiChangeValue: 'CHANGE_VALUE',
  }),
    // metodo para desplegar la ayuda
    showHelp(componentName, title, width) {
      this.uiChangeValue({
        varName: 'modalWidth',
        value: width,
      });
      this.uiSetDialogContent(componentName);
      this.uiSetDialogTitle(title);
      this.uiToggleDialog(true);
    }
  },
};
