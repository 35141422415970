<template lang="pug">
v-col(class="my-0 py-0" v-if="people.length > 0")
  v-list(class="my-0 py-0" dense)
    v-subheader(class="text-h5")
      | {{title}}: <b class="mx-2">{{people.length}}</b> Interesado(s)
    people-item(v-for="p in people" :person="p" :key="p.id")
</template>

<script>
import PeopleItem from '@/components/people/PeopleItem';

export default {
  name: 'PeopleDetail',
  props: {
    people: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  components: {
    PeopleItem
  }
};
</script>