<template lang="pug">
div
  v-card(
    class="pdf-wrapper"
    flat
    outlined)
    embed(v-if="pdfExists" class="pdf-file" :src="pdfUrl" scrolling="no")
    div(v-else class="mx-auto text-center")
      v-icon(color="red" size="60") mdi-file-document-remove
      v-card-text(class="pb-0")
        p(class="text text-h4 text--primary") 404
        p(class="text-overline") El archivo que intenta consultar no esta disponible en el sistema. Comuniquese con el administrador del sitio para obtener más información.
      div(class="pt-0" v-if="currentFolio.es_vur")
        v-btn(class="black--text" color="amber" @click="getRebuildPDF" :loading="loading") 
          v-icon(left size="25") mdi-hammer-wrench
          | Reconstruir Archivo
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { staticUrl } from '@/env';
import folioMixin from '@/mixins/folioMixin.js';

export default {
  name: 'FolioVisor',

  mixins: [folioMixin],

  props: {
    folioUrl: {
      type: String,
      required: true
    },
  },
  
  data () {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      pdfExists: false,
      loading: false,
    }
  },

  computed: {
    ...mapState('folios', ['currentFolio']),
    pdfUrl() {
      return `${staticUrl}${this.folioUrl}`
    }
  },

  async mounted() {
    await this.checkPdf();
  },

  async updated() {
    await this.checkPdf();
  },

  methods: {
    ...mapActions('vur', ['reBuildPdf']),
    async getRebuildPDF() {
      this.loading = true;
      await this.reBuildPdf({folioId: this.currentFolio.id});
      this.loading = false;
    },
    // metodo para verificar si el PDF existe
    async checkPdf() {
      if (this.folioUrl !== '') {
        this.pdfExists = await this.checkFileExists(`${staticUrl}${this.folioUrl}`);
      } else {
        this.pdfExists = false;
      }
    }
  }
};
</script>

<style scoped>
.visor-button {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.pdf-file {
  width: 100%;
  height: 100vh;
}
.pdf-wrapper {
  /* position: fixed; */
  left: 0px;
  bottom: 0px;
  z-index: 10000;
  /* width: 40vw!important; */
  height: 80vh;
}
</style>