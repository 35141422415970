<template lang="pug">
v-card
  v-toolbar(
    height="40"
    color="cyan"
    dark)
    v-toolbar-title Información Histórica de Catastro
  v-card-title(class="text-h5")
    | Folio de Matrícula Inmobiliaria  No. {{currentSearch.matricula}}

  div(class="d-flex mb-2")
  v-data-table(
    :headers="headers"
    :items="currentSearch.historical"
    hide-default-footer
    disable-pagination)
    template(v-slot:item.area_1="{ item }")
      | {{item.area_1.toFixed(1).replace('.', ',')}} m<sup>2</sup>
    template(v-slot:item.area_2="{ item }")
      | {{item.area_2.toFixed(1).replace('.', ',')}} m<sup>2</sup>
    template(v-slot:item.options="{ item }")
      v-btn(
        v-if="Object.keys(item.extra_data).length > 0"
        color="gray"
        icon
        @click="showInfo(item.extra_data, item.date, currentSearch.matricula)"
        title="Ver datos adicionales")
        v-icon mdi-information-variant-circle
    
  </template>
  
  <script>
  import { mapState, mapMutations } from 'vuex';
  
  export default {
    name: 'RecordHistorical',
    computed: {
      ...mapState('folios', ['currentSearch']),
    },
    methods: {
      ...mapMutations('ui', [
        'TOGGLE_DIALOG',
        'SET_DIALOG_CONTENT',
        'SET_DIALOG_TITLE',
        'CHANGE_VALUE',
      ]),
      ...mapMutations('folios', ['SET_EXTRA_DATA']),
      showInfo(data, date, record) {
        console.log('entrio showInfo', data);
        data.date = date
        data.record = record
        this.SET_EXTRA_DATA(data);
        this.SET_DIALOG_CONTENT('RecordExtraInfo');
        this.SET_DIALOG_TITLE('Información Adicional');
        this.CHANGE_VALUE({
        varName: 'modalWidth',
          value: '500',
        });
        this.TOGGLE_DIALOG(true);
      },
    },
    data: () => ({
      headers: [
      {
        text: 'Vigencia',
        align: 'start',
        filterable: false,
        value: 'date',
      },
      { 
        text: 'Dirección',
        value: 'address',
        align: 'left'
      },
      { 
        text: 'NPN',
        value: 'npn',
        align: 'center'
      },
      { 
        text: 'NPN Anterior',
        value: 'npn_old',
        align: 'center'
      },
      { 
        text: 'Departamento',
        value: 'depto'
      },
      {
        text: 'Municipio',
        value: 'city',
        align: 'center',
        sortable: false
      },
      { 
        text: 'Area Terreno',
        value: 'area_1',
        align: 'center'
      },
      {
        text: 'Area Construida',
        value: 'area_2',
        align: 'center'
      },
      { 
        text: 'Construcciones',
        value: 'buildings'
      },
      { 
        text: 'Opciones',
        value: 'options'
      },
    ],
    })
  }
  </script>
  