<template lang="pug">
v-card(class="truncated-folio-detail-height scrollable")
  v-toolbar(
    height="40"
    color="cyan"
    dark)
    v-toolbar-title Familia Genealógica del Folio
    v-spacer
    v-chip(class="mr-1 black--text" label color="yellow accent-4") Folio en el Sistema
    v-chip(class="mr-1 black--text" label color="grey") Folio NO Cargado 
    v-chip(class="mr-1 black--text" label color="red darken-1") Folio en Estudio
  v-row
    v-col(cols="8")
      JSCharting(
        :options="options()"
        class="chartDiv scrollable"
        @rendered="start"
        style="max-width: 100%;height: 560px")
    v-col(cols="4")
      folio-tradition-list(v-if="!vurMode" :annotation-list="traditionList")
      vur-family-logs(v-else :message-list="vurFamilyLogs")
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import JSCharting, { JSC } from 'jscharting-vue';
import FolioTraditionList from '@/components/folios/FolioTraditionList.vue';
import VurFamilyLogs from '@/components/vur/VurFamilyLogs.vue';

const highlightColor = '#5C6BC0', 
  mutedHighlightColor = '#0D47A1', 
  // mutedFill = '#f3f4fa', 
  nonExistentColorFill = '#9E9E9E',
  selectedFill = '#E8EAF6', 
  normalFill = 'white',
  nodeColorFill = '#FFCE30',
  seedColorFill = '#E53935';

export default {

  name: 'FolioChart',

  components: {
    JSCharting,
    FolioTraditionList,
    VurFamilyLogs,
  },

  props: {
    folioFamily: {
      type: Array,
      required: true
    },
    currentFolio: {
      type: String,
      required: true
    },
  },

  data(instance) {
    return {
      selectedAnnotations: [],
      me: null,
      chart: null,
      selectedPoint: null,
      options: () => ({
        debug: true, 
        type: 'organizational', 
        // defaultTooltip_enabled: true, 
        defaultPoint_label_text: function(point) {
          let colorStatus = '';
          switch (point.options('attributes.state')) {
            case 'Activo':
              colorStatus = '#33691E';
              break;
            case 'Cerrado':
              colorStatus = '#757575';
              break;
            case 'Desconocido':
              colorStatus = 'white';
              break;
            default:
              break;
          }
          let txt = `
            <span style="color:#000000; font-size:16px">${point.options('name')}</span><br>
            <span style="; font-size:14px;font-weight: 600; color:${colorStatus}">%state</span><br>
            <span style="color:black">%started</span>
          `;
          const transfers = point.options('attributes.transfers');
          for (let index = 0; index < transfers.length; index++) {
            const element = transfers[index];
            txt += `<span style="color:purple;font-size:16px;font-weight=600">*${element}</span>`;
          }
          return txt; 
        },
        // defaultPoint_tooltip_text: function(point) {
        //   const txt = '';
        //   console.log('defaultPoint_label_text', point);
        //   return txt; 
        // },
        /* These options will apply to all annotations including point nodes and breadcrumbs. */
        defaultAnnotation: { 
          padding: [5, 10], 
          margin: [15, 15] 
        },
        annotations: [ 
          { 
            position: 'bottom', 
            label_text: 
              '<span style="font-size=16px">En cada elemento: el número de matrícula inmobiliaria, <b>estado</b>, fecha de apertura y <b>Traslados</b>.</span>'
          } 
        ], 
        
        defaultSeries: { 
          color: normalFill, 
          /* Point selection is disabled because it is managed manually with point click events. */
          pointSelection: false
        }, 
        defaultPoint: { 
          focusGlow: false, 
          connectorLine: { 
            color: 'black', 
            radius: [0, 5],
            caps: { end: { type: 'arrow', size: 6 } }
          },
          tooltip: {
            text: '<b>%name</b><br/>%state'
          },
          outline: { color: '#e0e0e0', width: 1 }, 
          annotation: { syncHeight_with: 'level' }, 
          states: { 
            mute: { 
              opacity: 0.8, 
              outline: { 
                color: mutedHighlightColor, 
                opacity: 0.9, 
                width: 2 
              } 
            }, 
            select: { 
              enabled: true, 
              outline: { 
                color: highlightColor, 
                width: 2 
              }, 
              color: selectedFill 
            }, 
            // hover: { 
            //   outline: { 
            //     color: mutedHighlightColor, 
            //     width: 2 
            //   }, 
            //   color: mutedFill 
            // } 
          }, 
          events: { 
            click: function() { 
              instance.pointClick(this);
            }, 
          } 
        }, 
        // series: [{ points: instance.folioFamily }] 
        series: instance.getPoints(),
        toolbar_items: { 
          'Limpiar Selección': { 
            events_click: function() { 
              instance.resetStyles(instance.chart); 
              instance.selectedPoint = null;
              // limpier listado de tradicion
              instance.CLEAR_TRADITION_LIST();
            } 
          } 
        } 
      })
    };
  },

  computed: {
    ...mapState('folios', ['folioTradition', 'graphFolioNumber', 'traditionList']),
    ...mapState('vur', ['vurMode', 'vurFamilyLogs']),
  },

  methods: {
    ...mapMutations('folios', ['UPDATE_PARENT_LIST', 'CLEAR_TRADITION_LIST']),

    start(chart) {
      this.me = this;
      this.chart = chart;
    },

    getPoints() {
      const me = this;
      let pointsByDivision = JSC.nest() 
        .key('attributes.division') 
        .entries(this.folioFamily); 
      
      /* aplicar colo al folio de analisis */
      pointsByDivision.forEach(function(group) { 
        group.values.forEach(function(point) {
          let colorNode = '';
          if (point.name === me.graphFolioNumber) {
            colorNode = seedColorFill;
          } else {
            colorNode = nodeColorFill;
          }
          // Verificar si el folio existe en el sistema
          if (!point.avalaible) {
            colorNode = nonExistentColorFill;
          }
          JSC.merge(point, { 
            outline: { color:'black', width: 2 }, // #BDBDBD
            color: colorNode
          });
        }); 
      });

      return [{ points: this.folioFamily }];
    },

    pointClick(point) {
      // llenar lista con la tradicion del folio y sus matrices
      this.UPDATE_PARENT_LIST(point.id);
      const chart = point.chart;
      this.resetStyles(chart); 
      if (point.id === this.selectedPoint) { 
        this.selectedPoint = undefined; 
        return; 
      }
      this.selectedPoint = point.id;
      this.styleSelectedPoint(chart);
    }, 
      
    /** 
     * Styling helper functions 
     */
      
    styleSelectedPoint(chart) {
      const selectedPoint = this.selectedPoint;
      if (selectedPoint) {
        chart.connectors([selectedPoint, 'up'], { 
          color: highlightColor, 
          width: 2 
        });
        chart 
          .series() 
          .points([selectedPoint, 'up']) 
          .options({ selected: true, muted: false }); 
      } 
    }, 
      
    /** 
     * Clears connectors and point states. 
     * @param chart Chart object 
     */
    resetStyles(chart) {
      chart.connectors(); 
      chart 
        .series() 
        .points() 
        .options({ selected: false, muted: false }); 
      
    },
  },
  
};
</script>

<style scoped>
.chartDiv{
    margin: 8px auto;
    padding:15px;
    border-radius:10px;
}

.truncated-folio-detail-height {
  max-height: 600px;
  overflow-y: scroll;
}
</style>