<template lang="pug">
div
  v-btn(
    v-if="folioId !== ''"
      outlined
      :small="small"
      :title="`Ver folio ${folioNumber}`"
      @click="detailFolio(folioId)"
      color="green")
      v-icon mdi-checkbox-outline 
      | Ver Folio
  v-btn(
    v-else
    outlined
    :small="small"
    :title="`Descargar folio ${folioNumber} en el VUR`"
    @click="initializeVur(folioNumber)"
    color="red")
    v-icon mdi-checkbox-outline 
    | Descarga VUR
</template>

<script>
import folioMixin from '@/mixins/folioMixin.js';

export default {
  name: 'FolioChecker',

  mixins: [folioMixin],

  props: {
    // el id en el sistema del folio
    folioId: {
      type: String,
      default: ''
    },
    // el numero de la matricula inmobiliaria
    folioNumber: {
      type: String,
      default: ''
    },
    // indica si el boton se vera en tamaño pequeño
    small: {
      type: Boolean,
      default: true
    }
  },

}
</script>