<template lang="pug">
v-card(
  class="mx-auto"
  max-width="50%")
  v-list-item(two-line)
    v-list-item-content
      v-list-item-title(class="text-h5") Consultar Certificados de Tradición y Libertad
      v-list-item-subtitle
        | Para consultar el certificado, seleccione la oficina de registro y luego ingrese el número de la matricula
  
  vur-browser

  v-alert(v-if="selectedCertificate" class="mb-0" border="top" color="teal" dark)
    | {{ selectedCertificate.circle }} | {{ selectedCertificate.certificate }} | {{ selectedCertificate.identification }}
  property-visor(
    message='Seleccione un certificado para ver el predio'
    :reverse-coords="true"
    :cadastral-info="{match_database: selectedCertificate ? selectedCertificate.matchDb : '',\
      used_databases: selectedCertificate ? selectedCertificate.cadastralDbs : []}"
    :coordinates="selectedCertificate ? selectedCertificate.polygon.coordinates[0] : []")

  v-divider

  div(class="d-flex flex-row")
    v-list-item
      v-list-item-icon
        v-icon(color="green") mdi-check-circle
      v-list-item-subtitle <b>{{ validCertificates }}</b> Válido(s)

    v-list-item
      v-list-item-icon
        v-icon(color="yellow" style="background: black; border-radius: 20px;") mdi-alert-circle
      v-list-item-subtitle <b>{{ invalidCertificates }}</b> No verificado(s)

    v-list-item
      v-list-item-icon
        v-icon(color="blue") mdi-database-check
      v-list-item-subtitle <b>{{ processedCertificates }}</b> Procesados(s)

  certificates-list
  v-divider

  v-card-actions
    v-btn(
      class="white--text"
      :loading="loadingCancel"
      :disabled="loadingCancel"
      v-if="celeryTaskId !== ''"
      class="ma-2"
      color="red"
      @click="customActionCancel")
      v-icon(class="mr-1" dark) mdi-cancel
      | Cancelar
    v-spacer
    //- TODO certificados validos
      v-btn(
        v-if="hasPermission('can_use_vur')"
        :disabled="!validCertificates > 0"
        @click="sendCertificates"
        class="white--text"
        color='green') Consultar
    v-btn(
      v-if="hasPermission('can_use_vur')"
      :disabled="(validCertificates + invalidCertificates) < 1"
      @click="sendCertificates"
      class="white--text"
      color='green') Consultar 
    v-btn(v-else class="white--text" color="blue" @click="$router.push('/vur-credentials')")
      | Ingresar credenciales
      v-icon(right dark) mdi-arrow-right-bold
</template>

<script>
import celeryMixin from '@/mixins/celeryMixin.js';
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import VurBrowser from '@/components/vur/VurBrowser';
import CertificatesList from '@/components/vur/CertificatesList';
import { maxQueryCertificates } from '@/config.js';
import PropertyVisor from '@/components/PropertyVisor';

export default {
  name: 'VurManager',

  components: {
    CertificatesList,
    VurBrowser,
    PropertyVisor
  },

  mixins: [celeryMixin],

  data: () => ({
    labels: [],
    time: 0,
    maxQueryCertificates
  }),

  computed: {
    ...mapGetters('auth', ['hasPermission']),
    ...mapState('vur', ['certificates', 'selectedCertificate', 'celeryTaskId']),
    ...mapGetters('vur', ['validCertificates', 'invalidCertificates', 'processedCertificates']),
  },

  mounted() {
    this.getCircles();
  },

  methods: {
    ...mapActions('vur', ['getCircles', 'uploadCertificates']),
    ...mapMutations('ui', ['SHOW_ALERT']),
    ...mapMutations('vur', ['RESTART_NO_DOWNLOADED_CERTIFICATES']),
    sendCertificates() {
      // TODO validar envio con certificados validos
      // if (this.validCertificates === 0) {
      //   this.SHOW_ALERT({
      //     alertMessage: 'Debe haber por lo menos un certificado valido para enviar',
      //     alertType: 'error'
      //   });
      //   return;
      // }
      if ((this.validCertificates + this.invalidCertificates) === 0) {
        this.SHOW_ALERT({
          alertMessage: 'Debe haber por lo menos un certificado para enviar',
          alertType: 'error'
        });
        return;
      }
      this.uploadCertificates(false);
    },
    // metodo para cancelar la descarga de forma personalizada
    async customActionCancel() {
      // ejecutar accion de cancelación preestablecida por el mixin
      await this.actionCancel();
      // indicar que se pueden volver a descargar los folios
      // cuya descarga no ha iniciado
      this.RESTART_NO_DOWNLOADED_CERTIFICATES();
    }
  }
};
</script>
