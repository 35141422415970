<template lang="pug">
div
  //- Enlace para descargar el ZIP
  a(ref="downloadLink" hidden :href="downloadUrl" target="_blank")
  v-tooltip(v-if="zipPath === ''" top)
    template(v-slot:activator="{ on, attrs }")
      v-btn(
        :small="small"
        :loading="loadingDownload"
        @click="sendPdfs"
        v-bind="attrs"
        v-on="on"
        :disabled="folios.length === 0"
        class="blue white--text"
      ) Descargar PDF - (Solo existentes <b class="ml-2" style="font-size:22px">{{ folios.length }}</b>)
    span Trae los PDF en el sistema en un archivo ZIP
  v-tooltip(v-else top)
    template(v-slot:activator="{ on, attrs }")
      v-btn(
        :small="small"
        color="orange"
        class="white--text"
        v-bind="attrs"
        v-on="on"
        @click="download")
        v-icon(left dark) mdi-zip-box
        | Descargar Archivo
    span Descargar archivo en tu equipo
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { staticUrl } from '@/env';

export default {
  name: 'ButtonDownloadPdf',

  props: {
    // El listado de numeros de matricula cuyos pdfs van a ser descargados
    folios: {
      type: Array,
      required: true,
    },
    // propieda que indica si el tamaño del botón es pequeño
    small: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
  }),

  computed: {
    ...mapState('files', ['zipPath', 'loadingDownload']),
    // avalaibleFolios() {
    //   return this.folios.filter((x) => x.id !== '').length;
    // },
    downloadUrl() {
      return `${staticUrl}${this.zipPath}`
    }
  },

  methods: {
    ...mapActions('files', ['downloadPdfs']),
    async sendPdfs() {
      await this.downloadPdfs(this.folios);
    },
    download() {
      this.$refs.downloadLink.click();
    }
  }
};
</script>
    