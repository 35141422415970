// Vuex (state pattern)
import Vue from 'vue';
import Vuex from 'vuex';
import folios from '@/store/modules/folios';
import ui from '@/store/modules/ui';
import files from '@/store/modules/files';
import websocket from '@/store/modules/websocket';
import auth from '@/store/modules/auth';
import annotations from '@/store/modules/annotations';
import vur from '@/store/modules/vur';
import cadastral from '@/store/modules/cadastral';
import restrictions from '@/store/modules/restrictions';
import ant from '@/store/modules/ant';
import core from '@/store/modules/core';
import reports from '@/store/modules/reports';
import geos from '@/store/modules/geos';
import arkandha from '@/store/modules/arkandha';

Vue.use(Vuex);

// Crear una nueva instancia de store.
const store = new Vuex.Store();

// Registrar modulos en el store
store.registerModule('folios', folios);
store.registerModule('ui', ui);
store.registerModule('files', files);
store.registerModule('websocket', websocket);
store.registerModule('auth', auth);
store.registerModule('annotations', annotations);
store.registerModule('vur', vur);
store.registerModule('cadastral', cadastral);
store.registerModule('restrictions', restrictions);
store.registerModule('ant', ant);
store.registerModule('core', core);
store.registerModule('reports', reports);
store.registerModule('geos', geos);
store.registerModule('arkandha', arkandha);

export default store;
