<template lang="pug">
v-app  
  v-app-bar(
    app
    dense
    style="z-index: 100"
    color="blue darken-4"
    dark)
    v-app-bar-nav-icon(
      v-if="isLoggedIn"
      @click.stop="TOGGLE_SIDEBAR(true)")
    a(
      class="d-flex align-center"
      :href="arkandhaUrl"
      target="_blank"
      title="Ir a ARkandha")
      v-img(
        alt="ARkandha Logo"
        class="shrink mr-2"
        contain
        src="./assets/arkandha.png"
        transition="scale-transition"
        width="40")
      div
        span(class="text-h5 white--text") ARkandha<sup>®</sup>
        span(class="subtitle-1 ml-2 white--text") {{appName}}

    v-spacer

    v-sheet(v-if="isLoggedIn" width="70%")
      v-tabs(
        background-color="blue darken-4"
        dense
        next-icon="mdi-arrow-right"
        prev-icon="mdi-arrow-left"
        show-arrows
      )
        v-tab(
          small
          to="/restrictive-lists"
          text)
          span(class="mr-2") Listas Restrictivas
          v-icon mdi-account-alert
        v-tab(
          small
          to="/search"
          text)
          span(class="mr-2") Buscar bases Catastrales
          v-icon mdi-book-search-outline
        v-tab(
          small
          to="/vur"
          text)
          span(class="mr-2") Consulta VUR
          v-icon mdi-database-search-outline
        v-tab(
          small
          v-if="hasPermission('can_add_folio')"
          to="/upload"
          text)
          span(class="mr-2") Cargar Folios
          v-icon mdi-upload
        v-tab(
          small
          to="/folios"
          text)
          span(class="mr-2") Lista Folios
          v-icon mdi-file-multiple
        v-divider(vertical)
        v-tab(
          small
          @click="userLogout"
          text)
          span(class="mr-2") Salir
          v-icon mdi-logout
  side-bar
  v-alert(
    class="app-alert white--text"
    v-if="showAlert"
    dense
    :color="alertType"
    v-html="alertMessage")

  common-side-dialog
  common-modal
  common-bottom-modal

  v-main
    v-container(fluid)
      router-view
  //- Snackbar general de la aplicacion
  v-snackbar(
    v-model="showSnackbar"
    :timeout="snackbarTimeout")
    | {{ snackbarText }}
    template(v-slot:action="{ attrs }")
      v-btn(
        color="blue"
        text
        v-bind="attrs"
        @click="showSnackbar = false") Cerrar
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import CommonModal from './components/CommonModal';
import CommonSideDialog from './components/CommonSideDialog';
import { socketUrl, arkandhaUrl } from '@/env.js';
import SideBar from './components/layout/SideBar.vue';
import CommonBottomModal from './components/CommonBottomModal';

export default {
  name: 'App',
  
  components: {
    CommonModal,
    SideBar,
    CommonSideDialog,
    CommonBottomModal,
  },

  data: () => ({
    appName: process.env.VUE_APP_AR_NAME,
    arkandhaUrl,
  }),

  computed: {
    ...mapGetters('auth', ['isLoggedIn', 'hasPermission']),
    ...mapState('ui', [
      'showAlert',
      'alertType',
      'alertMessage',
      'snackbarTimeout',
      'snackbarText',
    ]),
    showSnackbar: {
      get() {
        return this.$store.state.ui.showSnackbar;
      },
      set(value) {
        return this.$store.commit('ui/CHANGE_VALUE', {
          value,
          varName: 'showSnackbar'
        });
      }
    }
  },

  watch: {
    $route(to, from) {
      if (to.path === '/folios' && from.path === '/login') {
        if (this.isLoggedIn === true) {
          this.connect();
        }
      }
    }
  },

  created() {
    if (this.isLoggedIn === true) {
      this.connect();
    }    
  },

  methods: {
    ...mapMutations('auth', ['LOGOUT']),
    ...mapMutations('ui', ['TOGGLE_SIDEBAR']),
    ...mapMutations('websocket', ['SOCKET_ON_CLOSE', 'SOCKET_ON_OPEN']),
    ...mapActions('websocket', ['socketMessage']),
    ...mapActions('auth', ['userLogout']),
    connect() {
      const token = localStorage.getItem('token');
      console.log('el valor del token', token);
      if (!!token === false) {
        return;
      }
      const clientUrl = `${socketUrl}/ws/${token}`;
      const notificationSocket = new WebSocket(
        clientUrl
      );

      notificationSocket.onopen = (event) => {
        console.log(`Conexion socket abierta exitosamente para ${clientUrl}`, event);
        this.SOCKET_ON_OPEN(notificationSocket);
      };

      notificationSocket.onmessage = (event) => {
        console.log('¡mensaje websocker recibido!', event);
        console.log(event.data);
        this.socketMessage(event.data);
      };

      notificationSocket.onclose = (event) => {
        console.log('¡Websocket cerrado!', event);
        this.SOCKET_ON_CLOSE();
        this.connect();
      };
    }
  },
};
</script>

<style scoped>
  .app-alert {
    margin-top: 55px!important;
    position: fixed;
    width: 100%;
    z-index: 1000;
  }
</style>
