<template lang="pug">
div
  v-container(fluid v-if="isMounted" ref="folioDetailContainer")

    folio-detail-conventions

    v-row(dense)
      v-col(cols="12")
        folio-main-info

    v-row
      v-col(cols="6")
        folio-location-info

      v-col(cols="6")
        folio-secondary-info

    v-row(v-if="currentFolio.vur_info")
      //- mostrar propietarios VUR primer tipo de almacenamiento
      v-col(cols="12" v-if="'data' in currentFolio.vur_info")
        vur-owners-info(
          v-if="'propietarios' in currentFolio.vur_info.data"
          :owners="currentFolio.vur_info.data.propietarios")
      v-col(cols="12" v-if="'propietarios' in currentFolio.vur_info")
        vur-owners-info(
          v-if="'propietarios' in currentFolio.vur_info"
          :owners="currentFolio.vur_info.propietarios")
    v-row
      v-col(cols="12")
        folio-annotations-info

    v-row
      v-col(cols="6")
        folio-associated-records

      v-col(cols="6")
        folio-warnings-info
    folio-tools
  loading-content(v-else)
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import FolioMainInfo from '@/components/folios/FolioMainInfo.vue';
import FolioLocationInfo from '@/components/folios/FolioLocationInfo.vue';
import FolioSecondaryInfo from '@/components/folios/FolioSecondaryInfo.vue';
import FolioAnnotationsInfo from '@/components/folios/FolioAnnotationsInfo.vue';
import FolioAssociatedRecords from '@/components/folios/FolioAssociatedRecords.vue';
import FolioWarningsInfo from '@/components/folios/FolioWarningsInfo.vue';
import VurOwnersInfo from '@/components/vur/VurOwnersInfo.vue';
import FolioDetailConventions from '@/components/folios/FolioDetailConventions.vue';
import folioMixin from '@/mixins/folioMixin.js';
import LoadingContent from '@/components/ui/LoadingContent.vue';
import FolioTools from '@/components/folios/FolioTools.vue';

export default {
  name: 'FolioDetail',

  components: {    
    FolioMainInfo,
    FolioLocationInfo,
    FolioSecondaryInfo,
    FolioAnnotationsInfo,
    FolioAssociatedRecords,
    FolioWarningsInfo,
    VurOwnersInfo,
    FolioDetailConventions,
    LoadingContent,
    FolioTools,
  },

  mixins: [folioMixin],

  data: () => ({
    isMounted: false,
  }),

  computed: {
    ...mapState('folios', ['currentFolio', 'viewedAnnotation']),
  },

  watch: {
    async $route(to) {
      const { folioId } = to.params;
      this.SET_ANNOTATION(null);
      // limpiar anotación seleccionada cuando se cambia de folio
      this.$bus.$emit('clear-annotation', null);
      // subir scroll del folio
      window.scroll({
        top: 0,
      });
      await this.getFolioDetail(folioId);
    }
  },

  async mounted() {
    const { folioId } = this.$route.params;
    await this.SET_ANNOTATION(null);
    await this.getFolioDetail(folioId);
    this.SELECT_ANNOTATION(null);
    this.isMounted = true;
  },

  methods: {
    ...mapMutations('folios', ['SET_ANNOTATION', 'SELECT_ANNOTATION']),
    ...mapActions('folios', ['getFolioDetail']),
  }
};
</script>
