<template lang="pug">
v-card(class="truncated-folio-detail-height scrollable")
  v-toolbar(
    height="40"
    :color="folioColor"
    dark)
    v-toolbar-title Matrículas matrices y segregadas
  v-subheader
    v-spacer
    v-btn(
      v-if="showFamily"
      small
      color="green"
      class="ma-2 white--text"
      title="Permite generar la familia registral con las matrículas relacionadas"
      @click="generateFamily")
      v-icon(left dark size="25") mdi-family-tree
      | Familia Registral
    v-btn(
      color="amber"
      small
      class="ma-2 white--text"
      title="Envía a la vista de descarga masiva las matrículas relacionadas"
      @click="loadVurRecords(totalRecords)")
      v-icon(left dark size="25") mdi-table-arrow-down
      | Descargar Folios Relacionados ({{ totalRecords.length }})
  v-row(class="mx-2 my-0")
    v-col(class="my-0 py-0")
      v-list(class="my-0 py-0" dense)
        v-subheader Matrículas Matrices (<b>{{filteredParents.length}}</b>)
        v-text-field(
          v-model="queryParent"
          :hint="hintMessageParent"
          small
          label="Ingrese dos o más caracteres")
        v-list-item(v-for="item in filteredParents" two-line :key="item.matricula")
          v-list-item-icon
            v-icon mdi-file-document
          v-list-item-content
            v-list-item-title {{item.matricula}}
            v-list-item-subtitle(class="d-flex flex-row")
          folio-checker(:folio-id="item.folio_id" :folio-number="item.matricula")
    v-col(class="my-0 py-0")
      v-list(class="my-0 py-0" dense)
        v-subheader Matrículas Segregadas (<b>{{filteredChildren.length}}</b>)
        v-text-field(
          v-model="queryChildren"
          :hint="hintMessageChildren"
          small
          label="Ingrese dos o más caracteres")
        v-list-item(v-for="item in filteredChildren" two-line :key="item.matricula")
          v-list-item-icon
            v-icon mdi-file-document
          v-list-item-content
            v-list-item-title {{item.matricula}}
            v-list-item-subtitle(class="d-flex flex-row")
              folio-checker(:folio-id="item.folio_id" :folio-number="item.matricula")
          annotation-badge(
            :show-annotation="true"
            :annotation-number="parseInt(item.anotacion)")

</template>

<script>
import { mapState, mapGetters } from 'vuex';
import folioMixin from '@/mixins/folioMixin.js';
import AnnotationBadge from '../annotations/AnnotationBadge.vue';
import FolioChecker from '@/components/folios/FolioChecker.vue';

export default {
  name: 'FolioAssociatedRecords',

  components: {
    AnnotationBadge,
    FolioChecker,
  },

  mixins: [folioMixin],

  data: () => ({
    queryParent: '',
    filteredParents: [],
    hintMessageParent: '',
    queryChildren: '',    
    filteredChildren: [],
    hintMessageChildren: '',
  }),

  computed: {
    ...mapGetters('folios', ['folioColor']),
    ...mapState('folios', ['currentFolio']),
    showFamily() {
      const parentsLen = this.currentFolio.abierto_con_base_en.length;
      const childrenLen = this.currentFolio.segregaciones.length;
      return parentsLen > 0 || childrenLen > 0;
    },
    totalRecords() {
      let records = [];
      // agregar las matriculas matrices
      records = records.concat(this.currentFolio.abierto_con_base_en.map((x) => x.matricula));
      // agregar las matriculas segregadas
      records = records.concat(this.currentFolio.segregaciones.map((x) => x.matricula));
      return records;
    }
  },

  watch: {
    queryParent(newValue) {
      if (newValue != '' && newValue.length >= 2) {
        this.filteredParents = this.currentFolio.abierto_con_base_en.filter((x) => 
          x.matricula.toLowerCase().includes(newValue.toLowerCase()));
        if (this.filteredParents.length > 0) {
          this.hintMessageParent = `Se encontraron ${this.filteredParents.length} resultado(s)`;
        } else {
          this.hintMessageParent = 'Sin resultados';
        }
      } else {
        this.filteredParents = this.currentFolio.abierto_con_base_en;
        this.hintMessageParent = '';
      }
    },
    queryChildren(newValue) {
      if (newValue != '' && newValue.length >= 2) {
        this.filteredChildren = this.currentFolio.segregaciones.filter((x) => 
          x.matricula.toLowerCase().includes(newValue.toLowerCase()));
        if (this.filteredChildren.length > 0) {
          this.hintMessageChildren = `Se encontraron ${this.filteredChildren.length} resultado(s)`;
        } else {
          this.hintMessageChildren = 'Sin resultados';
        }
      } else {
        this.filteredChildren = this.currentFolio.segregaciones;
        this.hintMessageChildren = '';
      }
    },
    '$store.state.folios.currentFolio': {
      deep: true,
      handler() {
        this.updateRelatedRecords();
      }
    }
  },
  
  mounted() {
    this.updateRelatedRecords();
  },

  methods: {
    updateRelatedRecords() {
      this.filteredParents = this.currentFolio.abierto_con_base_en;
      this.filteredChildren = this.currentFolio.segregaciones;
    },
  }
}
</script>
