<template lang="pug">
v-menu(top offset-y v-model="dialog" :close-on-content-click="false")
  template(v-slot:activator="{ on, attrs }")
    v-btn(
      color="primary"
      fab
      large
      dark
      :title="`${dialog ? 'Cerrar' : 'Ver herramientas'}`"
      bottom
      fixed
      right
      v-bind="attrs"
      v-on="on")
      v-icon {{ dialog ? 'mdi-window-close' : 'mdi-hammer-wrench' }}
  v-list(dense)
    v-subheader(class="text-h6")
      | Folio: <b class="ml-2">{{ currentFolio.numero_matricula }}</b>
    v-divider
    v-subheader(v-if="menu === 'cadastral'")
      v-icon(@click="menu = 'main'") mdi-arrow-left
      | Buscar en Bases Catastrales
    v-list-item-group(v-if="menu === 'cadastral'")
      v-list-item(
        :disabled="currentFolio.codigo_catastral === ''"
        @click="cadastralSearch('code', currentFolio.codigo_catastral)"
        :title="`Buscar: ${currentFolio.codigo_catastral}`")
        v-list-item-icon
          v-icon(
            :color="currentFolio.codigo_catastral === '' ? 'grey' : 'blue'") mdi-numeric-1-box
        v-list-item-content
          v-list-item-title Código Catastral
      v-list-item(
        :disabled="currentFolio.codigo_catastral_antiguo === ''"
        @click="cadastralSearch('code', currentFolio.codigo_catastral_antiguo)"
        :title="`Buscar: ${currentFolio.codigo_catastral}`")
        v-list-item-icon
          v-icon(
            :color="currentFolio.codigo_catastral_antiguo === '' ? 'grey' : 'blue'"
          ) mdi-numeric-2-box
        v-list-item-content
          v-list-item-title Código Catastral Anterior
      v-list-item(
        :disabled="currentFolio.cedula_catastral === ''"
        @click="cadastralSearch('code', currentFolio.cedula_catastral)"
        :title="`Buscar: ${currentFolio.cedula_catastral}`")
        v-list-item-icon
          v-icon(
            :color="currentFolio.cedula_catastral === '' ? 'grey' : 'blue'"
          ) mdi-numeric-3-box
        v-list-item-content
          v-list-item-title Cédula Catastral
      v-list-item(
        :disabled="currentFolio.numero_matricula === ''"
        @click="cadastralSearch('fmi', currentFolio.numero_matricula)")
        v-list-item-icon
          v-icon(
            :color="currentFolio.numero_matricula === '' ? 'grey' : 'blue'"
          ) mdi-numeric-4-box
        v-list-item-content
          v-list-item-title Matrícula Inmobiliaria
    v-list-item-group(v-if="menu === 'main'")
      v-list-item(@click="menu = 'cadastral'")
        v-list-item-icon
          v-icon(color="blue") mdi-earth-arrow-right
        v-list-item-content
          v-list-item-title Bases Catastrales
      v-list-item(@click="generateFamily")
        v-list-item-icon
          v-icon(color="green") mdi-family-tree
        v-list-item-content
          v-list-item-title Familia Registral
      v-list-item(
        v-if="$route.name === 'FolioDetail'"
        @click="analysisFolio(currentFolio.id)")
        v-list-item-icon
          v-icon(color="teal") mdi-scale-balance
        v-list-item-content
          v-list-item-title Análisis Jurídico
      v-list-item(
        v-if="$route.name === 'FolioAnalysis'"
        @click="detailFolio(currentFolio.id)")
        v-list-item-icon
          v-icon(color="teal") mdi-file-certificate-outline
        v-list-item-content
          v-list-item-title Información principal
      v-list-item(@click="showPdf")
        v-list-item-icon
          v-icon(color="red") mdi-file-pdf-box
        v-list-item-content
          v-list-item-title Ver PDF
      v-list-item(@click="downloadPdf")
        v-list-item-icon
          v-icon(color="red") mdi-file-download
        v-list-item-content
          v-list-item-title Descargar PDF
      v-list-item(@click="syncFolio" :disabled="loadingFolio")
        v-list-item-icon
          v-icon(color="purple") mdi-sync-circle
        v-list-item-content
          v-list-item-title
            | {{ loadingFolio ? 'Sincronizando...' : 'Sincronizar Folio'}}
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import folioMixin from '@/mixins/folioMixin.js';
import { staticUrl } from '@/env';

export default {
  name: 'FolioTools',

  mixins: [folioMixin],

  data () {
    return {
      // propiedad que indica el estado del menu
      dialog: false,
      // propieda que indica el menu actual de herramientas
      menu: 'main',
    }
  },

  computed: {
    ...mapState('folios', ['currentFolio', 'loadingFolio']),
  },

  methods: {
    ...mapMutations('ui', ['SHOW_BOTTOM_MODAL', 'SHOW_SNACKBAR']),
    ...mapActions('folios', ['getFolioDetail']),
    // metodo para ver el pdf del folio
    showPdf() {
      this.SHOW_BOTTOM_MODAL({
        dialogTitle: `Archivo PDF para el folio: <b>${this.currentFolio.numero_matricula}</b>`,
        componentName: 'FolioVisor',
        properties: {
          folioUrl: this.currentFolio.url_archivo,
        }
      });
    },
    // metodo para descargar el PDF del folio
    async downloadPdf() {
      if (this.currentFolio.url_archivo !== '') {
        const url = `${staticUrl}${this.currentFolio.url_archivo}`;
        const exists = await this.checkFileExists(url);
        if (!exists) {
          this.SHOW_SNACKBAR({
            message: 'Ocurrio un incoveniente con al tratar de conseguir el PDF\
              de este folio. Intente reconstruirlo',
          });
          return;
        }
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${this.currentFolio.numero_matricula}.pdf`); // Opcional, algunos servidores lo bloquean
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        this.SHOW_SNACKBAR({
          message: 'Este folio no cuenta con un PDF para descargar.\
            Puede comunicarse con soporte',
        });
      }
    },
    // metodo para sincronizar el folio sin ercarga completa de la pagina
    async syncFolio() {
      await this.getFolioDetail(this.currentFolio.id);
    }
  }
};
</script>