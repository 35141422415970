<template lang="pug">
v-container(class="text-center" fluid)
  h1
    v-icon(color="red" size="45") mdi-file-chart
    | Pre-Diagnóstico Dinámico
  massive-folio-report-manager
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import MassiveFolioReportManager from '@/components/reports_cmp/MassiveFolioReportManager';

export default {
  name: 'DynamicReportView',

  components: {
    MassiveFolioReportManager
  },

  data: () => ({
  }),

  computed: {
  },

  async mounted() {
    // limpiar los datos del reporte
    this.CHANGE_VALUE({
      value: null,
      varName: 'reportData'
    })
    // traer variables para reporte de los folios
    await this.getFolioVariables();
    // traer las plantillas de reportes de filios
    await this.getFolioReports();
  },

  methods: {
    ...mapActions('reports', ['getFolioVariables', 'getFolioReports']),
    ...mapMutations('reports', ['CHANGE_VALUE']),
  }
};
</script>
      