<template lang="pug">
v-card
  v-toolbar(
    height="40"
    :color="folioColor"
    dark)
    v-toolbar-title Información general del folio
  v-card-title(class="text-h5 d-flex")
    span(class="mr-2") Folio de Matrícula Inmobiliaria  
    span(class="mr-2") {{currentFolio.numero_matricula}}
    v-spacer
    span(v-if="currentFolio.transfer_info")
      span(v-if="currentFolio.transfer_info.length > 0" class="mr-2 red--text text-h4") <b class="pulse">Folio TRASLADADO</b>
      v-row(class="mt-1")
        span(class="mr-1") Matrículas anteriores:
        v-chip(
          v-for="t in currentFolio.transfer_info"
          class="text-h5 mr-2 py-2 mb-3"
          color="indigo"
          text-color="white"
          :key="t.matricula_origen"
          title="Ver información traslado:") {{t.matricula_origen}}

  div(class="d-flex mb-2")
    v-card-subtitle(class="my-auto")
      div Turno
    v-card-text(class="text--primary my-auto")
      div {{currentFolio.turno}}

    v-card-subtitle(class="my-auto")
      div Círculo Registral
    v-card-text(class="text--primary my-auto")
      div {{currentFolio.nombre_circulo}}

    v-card-subtitle(class="my-auto")
      div Estado del folio
    v-card-text(class="text--primary text-h4 my-auto")
      div {{currentFolio.estado}}

  v-divider(class="mb-2")

  div(class="d-flex pb-2")
    v-card-subtitle(class="my-auto")
      div Código Catastral
    v-card-text(class="text--primary text-h6 my-auto")
      div {{currentFolio.codigo_catastral}}

    v-card-subtitle(class="my-auto")
      div Código Catastral Anterior
    v-card-text(class="text--primary text-h6 my-auto")
      div {{currentFolio.codigo_catastral_antiguo}}

    v-card-subtitle(class="my-auto")
      div Cédula Catastral
    v-card-text(class="text--primary text-h6 my-auto")
      div {{currentFolio.cedula_catastral}}

  div(class="d-flex pb-2")
    v-card-subtitle(class="my-auto")
      div Radicación
    v-card-text(class="text--primary text-h6 my-auto")
      div {{currentFolio.radicacion}}

    v-card-subtitle(class="my-auto")
      div Fecha de Apertura
    v-card-text(class="text--primary text-h6 my-auto")
      div {{currentFolio.fecha_apertura}}

    v-card-subtitle(class="my-auto")
      div Fecha de Expedición
    v-card-text(class="text--primary text-h6 my-auto")
      div {{currentFolio.fecha_solicitud}}
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'FolioMainInfo',
  computed: {
    ...mapGetters('folios', ['folioColor']),
    ...mapState('folios', ['currentFolio']),
  }
}
</script>

<style scoped>
.pulse {
  animation: pulse 1s infinite ease-in-out alternate;
}
@keyframes pulse {
  from { transform: scale(0.8); }
  to { transform: scale(1.2); }
}
</style>
