import ArkandhaService from '@/services/ArkandhaService.js';

const vur = {
  namespaced: true,

  state: {
    projects: [],
  },

  actions: {
    async getProjects({ commit, state }) {
      try {
        const response = await ArkandhaService.getProjects();
        console.log(response);
        state.projects = response.data;
      } catch (error) {
        console.log(error);
        commit('ui/SHOW_ALERT',{
          alertMessage: `Ocurrio un error al cargar los proyectos Arkandha
            (${error.response.status} - ${error.response.data.detail})`,
          alertType: 'error'
        }, {root: true});
      }
    },
  },
};

export default vur;