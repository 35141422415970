<template lang="pug">
v-list(two-line dense)
  v-list-item
    v-list-item-content()
      | Con esta herramienta usted podrá consultar la información alfanumérica
      | y geográfica de las fuentes catastral y registral disponibles en
      | ARkandha en distintos periodos de tiempo. Para ello usted deberá
      | introducir: <b>Número predial, Número Predial anterior, Matrícula Inmobiliaria
      | o Municipio </b> según corresponda.
  v-divider
  v-list-item-group
    v-subheader EJEMPLO BASE IGAC
    v-list-item
      v-list-item-icon(class="mb-0")
        img(
          class="flag"
          src="@/assets/logo_igac.svg"
          width="40")
      v-list-item-content(class="py-0")
        v-list-item-title Catastro Igac
        v-list-item-subtitle <b>Código</b>: 910010000000000010082000000000
        v-list-item-subtitle <b>Código Incompleto</b>: 000000010082000 <b>Municipio</b>: Leticia
    v-subheader EJEMPLOS CATASTROS DECENTRALIZADOS
    v-list-item
      v-list-item-icon(class="mb-0")
        img(
          class="flag"
          src="https://arkandha.net/static/img/mail-images/bandera_antioquia.png"
          width="40")
      v-list-item-content(class="py-0")
        v-list-item-title Catastro Antioquia
        v-list-item-subtitle <b>Código</b>: 055912003000000100045000000000
    v-list-item
      v-list-item-icon(class="mb-0")
        img(
          class="flag"
          src="https://arkandha.net/static/img/mail-images/bandera_barranquilla.png"
          width="40")
      v-list-item-content(class="py-0")
        v-list-item-title Catastro Barranquilla
        v-list-item-subtitle <b>Código</b>: 080010116000001220001500000116
    v-list-item
      v-list-item-icon(class="mb-0")
        img(
          class="flag"
          src="https://arkandha.net/static/img/mail-images/bandera_bogota.png"
          width="40")
      v-list-item-content(class="py-0")
        v-list-item-title Catastro Bogotá
        v-list-item-subtitle <b>Código</b>: 110010092420190010000000000000
    v-list-item
      v-list-item-icon(class="mb-0")
        img(
          class="flag"
          src="https://arkandha.net/static/img/mail-images/bandera_cartagena.png"
          width="40")
      v-list-item-content(class="py-0")
        v-list-item-title Catastro Cartagena
        v-list-item-subtitle <b>Código</b>: 130010900000000770006000000000
    v-list-item
      v-list-item-icon(class="mb-0")
        img(
          class="flag"
          src="https://arkandha.net/static/img/mail-images/bandera_cali.png"
          width="40")
      v-list-item-content(class="py-0")
        v-list-item-title Catastro Cali
        v-list-item-subtitle <b>Código</b>: 760010000510000050041000000000
    v-list-item
      v-list-item-icon(class="mb-0")
        img(
          class="flag"
          src="https://arkandha.net/static/img/mail-images/bandera_cundinamarca.png"
          width="40")
      v-list-item-content(class="py-0")
        v-list-item-title Catastro Cundinamarca
        v-list-item-subtitle <b>Código</b>: 253170001000000120103000000000
    v-list-item
      v-list-item-icon(class="mb-0")
        img(
          class="flag"
          src="https://arkandha.net/static/img/mail-images/bandera_medellin.png"
          width="40")
      v-list-item-content(class="py-0")
        v-list-item-title Catastro Medellín
        v-list-item-subtitle <b>Código</b>: 050010008708900050020000000000
    v-list-item
      v-list-item-icon(class="mb-0")
        img(
          class="flag"
          src="https://arkandha.net/static/img/mail-images/bandera_tunja.png"
          width="40")
      v-list-item-content(class="py-0")
        v-list-item-title Catastro Tunja
        v-list-item-subtitle <b>Código</b>: 150010103000000420020000000000
</template>

<script>
export default {
  name: 'CadastralHelp',
};
</script>

<style scope>
.flag {
  background: #BDBDBD!important;
  padding: 3px!important;
}
</style>