<template lang="pug">
v-card
  v-toolbar(
    height="40"
    :color="folioColor"
    dark)
    v-toolbar-title Localización del predio según autoridad catastral
    v-spacer
    a(href="" id="export" ref="exportLink" hidden)
    v-btn(
      v-if="currentFolio.geom"
      color="green"
      class="ma-2 white--text"
      @click="getKLM")
      | Descargar kml
      v-icon(
        right
        dark) mdi-cloud-download-outline
  property-visor(
    ref="propertyVisor"
    :cadastral-info="currentFolio.bases_consultadas"
    :coordinates="currentFolio.geom ? currentFolio.geom.coordinates[0] : []")
  v-divider
  div(class="d-flex")
    v-card-subtitle(class="my-auto")
      div Departamento (Registro)
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentFolio.departamento}}

    v-card-subtitle(class="my-auto")
      div Municipio (Registro)
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentFolio.municipio_txt}}

    v-card-subtitle(class="my-auto")
      div Vereda (Registro)
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentFolio.vereda}}
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import tokml from 'tokml';
import PropertyVisor from '@/components/PropertyVisor';

export default {
  name: 'FolioLocationInfo',

  components: {
    PropertyVisor
  },

  computed: {
    ...mapState('folios', ['currentFolio']),
    ...mapGetters('folios', ['folioColor']),
  },

  methods: {
    getKLM() {
      const data = this.$refs.propertyVisor.polygon.toGeoJSON();
      // agregar los atributos al geojson
      let propertyData = {};
      propertyData.tipo = this.currentFolio.tipo_predio;
      propertyData.ubicacion = `${this.currentFolio.municipio_txt} (${this.currentFolio.departamento})`;
      const cadastralData = this.currentFolio.bases_consultadas;
      propertyData.base = 'match_database' in cadastralData ? cadastralData.match_database : '';
      propertyData.area = this.currentFolio.area_terreno; 
      propertyData.matricula = this.currentFolio.numero_matricula;

      data.properties = propertyData;
      var kml = tokml(data);

      var convertedData = 'application/xml;charset=utf-8,' + encodeURIComponent(kml);

      // if you want to use the official MIME type for KML
      // var convertedData = 'application/vnd.google-earth.kml+xml;charset=utf-8,' + 
      // encodeURIComponent(kml);
      const today = new Date();
      let formatDate = today.toISOString().replace('T', '_').replace('Z', '');
      formatDate = formatDate.slice(0, formatDate.length - 4);
      document.getElementById('export').setAttribute('href', 'data:' + convertedData); 
      document.getElementById('export').setAttribute('download',
        `folio_${this.currentFolio.numero_matricula}_predio_${formatDate}.kml`);
      this.$refs.exportLink.click();
    }
  }
}
</script>
