<template lang="pug">
v-list(two-line dense)
  v-list-item
    v-list-item-content
      | Con esta herramienta usted podrá consultar la familia registral de un folio
      | de matrícula inmobiliaria, es decir, las matriculas matrices y segregadas
      | de las cuales se desprendio y se desprendieron de este númeero de matrícula
      | respectivamente. Si algunos de los elementos no esta disponible en el sitema
      | ARkandha tambien cuenta con la funcionalidad de completar la familia por medio
      | de la descarga de los folios desde el VUR.
  v-divider
  v-list-item
    v-list-item-content
      div Ejemplos de familias registrales generadas con ARkandha: Folio <b class="red--text">{{ items[step].folio }}</b>
      v-carousel(class="pb-4" height="400" v-model="step")
        v-carousel-item(
          v-for="(item,i) in items"
          :key="i"
          reverse-transition="fade-transition"
          transition="fade-transition")
          v-zoomer(
            v-if="item.type === 'image'"
            style="width: 100%; height: 300px; border: solid 1px silver;")
            img(              
              :src="require(`@/assets/families/${item.image}`)"
              style="object-fit: contain; width: 100%; height: 100%;")
          video(
            controls
            v-if="item.type === 'video'"
            style="object-fit: contain; width: 100%; height: 360px;")
            source(:src="`/static/videos/${item.image}`")
</template>

<script>
export default {
  name: 'CadastralHelp',

  data: () => ({
    // los elementos de carrusel
    items: [
      {
        image: '060-267910.png',
        folio: '060-267910',
        type: 'image',
      },
      {
        image: '088-10553.png',
        folio: '088-10553',
        type: 'image',
      },
      {
        image: '132-1.png',
        folio: '132-1',
        type: 'image',
      },
      {
        image: '132-72454.png',
        folio: '132-72454',
        type: 'image',
      },
      {
        image: '142-32007.png',
        folio: '142-32007',
        type: 'image',
      },
      {
        image: '200-191951.png',
        folio: '200-191951',
        type: 'image',
      },
      {
        image: 'familia_registral.mp4',
        folio: 'Familia registral 070-223593',
        type: 'video',
      },
    ],
    // el paso actual del carrusel
    step: 0
  }),
};
</script>

<style scope>
.flag {
  background: #BDBDBD!important;
  padding: 3px!important;
}
</style>