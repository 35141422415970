import FoliosService from '@/services/FoliosService.js';

const folios = {
  namespaced: true,
  state: {
    selectedAnnotation: null,
    viewedAnnotation: null,
    selectedMeasure: null,
    viewedMeasure: null,
    items: [],
    currentFolio: null,
    currentSearch: {
      poligono: null
    },
    totalPages: 0,
    viewedExtraInfo: null,
    loadingFolios: true,
    // consulta masiva
    csvData: null,
    // familia de folios
    folioFamily: [],
    folioTradition: [],
    graphData: null,
    graphFolioNumber: '',
    traditionList: [],
    segregations: {},
    // esatdo para indicar que el detalle del folio esta cargando
    loadingFolio: false
  },
  mutations: {
    SET_ANNOTATION(state, annotation) {
      state.viewedAnnotation = annotation;
    },
    SET_EXTRA_DATA(state, data) {
      state.viewedExtraInfo = data;
    },
    SELECT_ANNOTATION(state, annotation) {
      state.selectedAnnotation = annotation;
    },
    SELECT_MEASURE(state, measure) {
      if (measure !== null) {
        state.viewedMeasure = state.currentFolio.measures[measure];          
      }
      state.selectedMeasure = measure;
    },
    SET_FOLIOS(state, folios) {
      state.items = folios;
    },
    SET_PAGES(state, totalPages) {
      state.totalPages = totalPages;
    },
    SET_FOLIO(state, folio) {
      state.currentFolio = folio;
    },
    SET_SEARCH(state, search) {
      state.currentSearch = search;
    },
    CLEAR_SEARCH(state) {
      state.currentSearch = {
        poligono: null
      }
    },
    VIEW_ANNOTATION(state, annotationNumber) {
      annotationNumber = parseInt(annotationNumber);
      state.selectedAnnotation = annotationNumber - 1;
      const annotation = state.currentFolio.anotaciones.filter(
        (x) => x.numero === annotationNumber)[0];
      state.viewedAnnotation = annotation;
    },
    // mutacion para remover el atributo tradition de la familia del folio
    REMOVE_TRADITION(state) {
      for (let index = 0; index < state.folioFamily.length; index++) {
        const element = state.folioFamily[index];
        // eliminar atributo tradicion para evitar conflictos
        delete element.tradition;
        // eliminar el atributo parent de los folios sin padre para evitar conflictos
        // en ele renderizado del grafo
        if (element.parent.length === 0) {
          delete element.parent;
        } else {
          // truncar el nuemro de matriculas para no sobrecargar el
          // renderizado de la pagina
          if (element.parent.length > 15) {
            element.parent = element.parent.slice(0, 15);
          }
        }
        
      }
    },
    // mutacion para cuando no se encuentran resultados
    TREE_NOT_FOUND(state, search) {
      state.folioFamily = [{
        attributes: {
          started: "Desconocido",
          state: "Desconocido"
        },
        avalaible: false,
        children: [],
        color: "#9E9E9E",
        folio_id: "",
        name: search,
        id: search
      }];
      state.folioTradition = [],
      state.graphData = null;
      state.graphFolioNumber = '';
    },
    UPDATE_PARENT_LIST(state, folioNumber) {
      const traditionAnnotations = [];
      const parents = []
      // función para encontrar de manera recursiva la tradición
      function getTradition(folio) {
        for (let index = 0; index < folio.length; index++) {
          const currentFolio = folio[index];
          let seed = state.folioTradition.filter(x => x.id === currentFolio);
          parents.unshift(currentFolio);
          if (seed.length > 0) {
            seed = seed[0];
            traditionAnnotations.unshift({
              folio: currentFolio,
              folioId: seed.folio_id,
              annotations: seed.tradition
            });
            if (seed.tradition.length > 0) {
              getTradition(seed.parent);
            }
          }
        } 
      }
      getTradition([folioNumber]);

      // agregar los numeros de las anotaciones en las segregaciones
      for (let index = 0; index < traditionAnnotations.length; index++) {
        const folio = traditionAnnotations[index];
        const annotations = state.segregations.filter(x => x.child === folio.folio.replace('_', '-'));
        const segregations = []
        for (let index2 = 0; index2 < annotations.length; index2++) {
          const annotation = annotations[index2];
          segregations.push({
            folio: annotation.parent,
            number: annotation.annotation
          })
        }
        folio.segregations = segregations;
      }
    
      state.traditionList = traditionAnnotations;
    },
    // mutacion para limpiar la tradicion
    CLEAR_TRADITION_LIST(state) {
      state.traditionList = [];
    },
    // Mutacion para agregar un nodo al grafo de la familia registral
    LOAD_FAMILY_NODE(state, {fmiData}) {
      const familyItems = state.folioFamily.map((x) => x.id);
      // ver que el folio no se encuentre an la familia
      // caso en que dos lineas de matriz se unan en el folio
      let missingFolio = false;
      let folioNumberExists = familyItems.includes(fmiData.id);
      if (folioNumberExists) {
        missingFolio = state.folioFamily.filter((x) => x.id === fmiData.id)[0].avalaible === false;
      }
      if (folioNumberExists && !missingFolio) {
        return;
      }
      
      // conectar con los hijos en la familia
      for (let index = 0; index < fmiData.children.length; index++) {
        const fmiChild = fmiData.children[index];
        const childFilter = state.folioFamily.filter(x => x.id === fmiChild);
        // verificar si el elemento existe en la lista
        if (childFilter.length === 0) {
          continue;
        }
        const child = childFilter[0];
        if ('parent' in child) {
          // validar que el padre no haya sido agregado desde otro folio
          if (!child.parent.includes(fmiData.id)) {
            child.parent.push(fmiData.id);
          }
        } else {
          child.parent =[fmiData.id];
        }
      }
      // remover los padres que no están en el folios
      let hasParentFamily = false;
      const validParent = [];
      for (let index = 0; index < fmiData.parent.length; index++) {
        const fmiParent = fmiData.parent[index];
        if (familyItems.includes(fmiParent)) {
          hasParentFamily = true;
          validParent.push(fmiParent);
        }
      }
      // asignar los padres procesados al folio
      fmiData.parent = validParent;

      let folioData = JSON.stringify(fmiData);
      let folioTradition = JSON.parse(folioData);
      let folioFamily = JSON.parse(folioData);

      // procesar los elementos del grafo para evitar conflictos
      delete folioFamily.tradition
      if (folioFamily.parent.length === 0) {
        delete folioFamily.parent;
      }

      // actualizar nodo familia existente
      if (folioNumberExists) {
        const nodeIndex = familyItems.indexOf(fmiData.id);
        state.folioTradition.splice(nodeIndex, 1, folioTradition);
        state.folioFamily.splice(nodeIndex, 1, folioFamily);
      } else {
        if (hasParentFamily === true) {
          state.folioTradition.push(folioTradition);
          state.folioFamily.push(folioFamily);        
        } else {
          state.folioTradition.unshift(folioTradition);
          state.folioFamily.unshift(folioFamily);
        }
      }
    },
  },
  getters: {
    folioColor: (state) => state.currentFolio.es_vur ? 'red darken-2' : 'cyan',
    truncatedFolios: (state) => state.folioTradition.filter((x) =>
      x.truncated.children.length > 0 || x.truncated.parents.length > 0),
    // getFolioTradition: (state) => (folio) => {
    //   const item = state[source].items.filter((x) => x.id === itemId)[0];
    //   return item;
    // }
  },
  actions: {
    async getFolios({ commit, state }, { pageSize, page, searchQuery }) {
      const token = localStorage.getItem('token');
      state.loadingFolios = true;
      try {
          const response = await FoliosService.getFolios(token, pageSize, page, searchQuery);
          console.log('respuesta folios', response);
          if (response) {
            commit('SET_FOLIOS', response.data.folios);
            commit('SET_PAGES', response.data.total_pages);
          }
      } catch (error) {
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrio un error al cargar los folios
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
      state.loadingFolios = false;
    },
    async getFolioDetail({ commit, state }, folioId) {
      const token = localStorage.getItem('token');
      state.loadingFolio = true;
      try {
          const response = await FoliosService.getFolioDetail(folioId, token);
          if (response) {
            commit('SET_FOLIO', response.data);
            commit('annotations/SET_ANNOTATIONS', {
              annotations: response.data.anotaciones},
              {root: true});
          }
          state.loadingFolio = false;
      } catch (error) {
        state.loadingFolio = false;
        commit('ui/SHOW_ALERT',{
          alertMessage: `Ocurrio un error al cargar la informacion del folio
            (${error.response.status} - ${error.response.data.detail})`,
          alertType: 'error'
        }, {root: true});
      }
    },
    async folioSearch({ commit }, {npn, npnOld, propertyRecord, cityCode}) {
      const token = localStorage.getItem('token');
      try {
          const response = await FoliosService.folioSearch(npn, npnOld, propertyRecord, cityCode, token);
          if (response) {
            commit('SET_SEARCH', response.data);
            if (!response.data.poligono && !response.data.registros) {
              commit('ui/SHOW_ALERT',{
                alertMessage: 'No hubo resultados que coincidencia con los criterios de la busqueda',
                alertType: 'warning'
              }, {root: true});
            } else {
              commit('ui/SHOW_ALERT',{
                alertMessage: 'Se encontró una coincidencia con los criterios de búsqueda',
                alertType: 'success'
              }, {root: true});
            }
          }
      } catch (error) {
        commit('ui/SHOW_ALERT',{
          alertMessage: `Ocurrio un error en la consulta de la información
            (${error.response.status} - ${error.response.data.detail})`,
          alertType: 'error'
        }, {root: true});
      }
    },
    async searchFamily({ commit, state }, {propertyRecord}) {
      const token = localStorage.getItem('token');
      state.graphData = null;
      state.graphFolioNumber = propertyRecord;
      state.traditionList = [];
      state.segregations = [];
      try {
          const response = await FoliosService.searchFamily(propertyRecord, token);
          if (response) {
            let family = JSON.stringify(response.data.graph);
            state.folioTradition = JSON.parse(family);
            state.folioFamily = JSON.parse(family);
            state.segregations = response.data.segregations;
            state.graphData = `data:image/png;base64, ${response.data.image}`;
            // aliminar los datos de tradicion para los datos del grafo
            commit('REMOVE_TRADITION')
            if (!response.data.graph) {
              commit('ui/SHOW_ALERT',{
                alertMessage: 'No se pudo generar la familia registral del folio',
                alertType: 'warning'
              }, {root: true});
            } else {
              commit('ui/SHOW_ALERT',{
                alertMessage: 'Se encontró una coincidencia con los criterios de búsqueda',
                alertType: 'success'
              }, {root: true});
            }
          }
      } catch (error) {
        if (error.response.status === 404) {
          commit('TREE_NOT_FOUND', propertyRecord);
        }
        commit('ui/SHOW_ALERT',{
          alertMessage: `Ocurrio un error en la consulta de la información
            (${error.response.status} - ${error.response.data.detail})`,
          alertType: 'error'
        }, {root: true});
      }
    },
    // url para la búsqueda masiva de predios por npn, npn anterior o fmi
    async masiveFolioSearch({ commit, state }, {file, field}) {
      // limpiar el contenido de la busqueda anterior
      state.csvData = null;
      try {
        const token = localStorage.getItem('token');
        const response = await FoliosService.masiveFolioSearch(file, field, token);
        if (response) {
          state.csvData = `data:text/csv,${response.data}`;
          commit('ui/SHOW_ALERT',{
            alertMessage: 'Se cargó correctamente el listado en el sistema',
            alertType: 'success'
          }, {root: true});
        }
      } catch (error) {
          console.log(error);
          commit('ui/SHOW_ALERT',{
            alertMessage: `Ocurrió un error al cargar el listado en el sistema
              (${error.response.status} - ${error.response.data.detail})`,
            alertType: 'error'
          }, {root: true});
      }
      state.loadingExcel = false;
    },
  },  
};

export default folios;
