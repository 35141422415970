<template lang="pug">
v-list(two-line)
  list-convention-icon(:conventions="conventions")
  v-list-item-group(
    v-model="selectedSources"
    active-class="green--text"
    multiple)
    template(v-for="(item, index) in filteredSources")
      v-list-item(
        :key="item.title"
        v-ephemeral:nuevo="item.new_date"
        :disabled="!item.active")
        template(v-slot:default="{ active }")
          v-list-item-action
            v-checkbox(:input-value="active")
          v-list-item-avatar(size="55")
            v-img(
              :alt="item.title"
              :src="require(`@/assets/restrictive_lists/${item.logo}`)")
          v-list-item-content
            v-list-item-title(v-text="item.name")
            v-list-item-subtitle(
              class="text--primary"
              v-text="item.origin")
            v-list-item-subtitle(v-text="item.desc")
            v-list-item-subtitle(v-if="item.identification_types" class="text--primary mt-1")
              |<b>TIPOS DE IDENTIFICACIÓN PERMITIDOS</b>
            v-chip-group(
              active-class="primary--text"
              column)
              v-tooltip(v-for="tag in item.identification_types" bottom :key="`${item.name}-${tag}`")
                template(v-slot:activator="{ on, attrs }")
                  v-chip(
                    class="white--text"
                    color="red"
                    :key="tag"
                    v-bind="attrs"
                    v-on="on") <b>{{ tag }}</b>
                span {{getTitle(tag)}}
          v-list-item-action
            v-list-item-action-text(v-text="`${item.active ? 'Activo' : 'Deshabilitado'}`")
            div(v-if="item.active && item.status")
              v-icon(
                v-if="item.status === 'not_found'"
                size="35"
                title="No encontrado"
                color="green darken-3") mdi-account-check
              v-icon(
                v-else-if="item.status === 'found'"
                size="35"
                title="ENCONTRADO"
                color="red darken-3") mdi-account-cancel
              v-icon(
                v-else-if="item.status === 'error'"
                size="35"
                title="ERROR"
                color="yellow darken-3") mdi-alert
              v-icon(
                v-else-if="item.status === 'search'"
                class="spinner"
                size="35"
                title="Buscando..."
                color="blue darken-3") mdi-refresh
              v-icon(
                v-else
                size="35"
                title="Sin Información/No Aplica"
                color="indigo") mdi-account-alert
            div(v-if="item.error !== ''")
              text-cutter(
                :text="item.error || ''"
                :text-length="30"
                modal-title="Mensaje error consultado al consultar lista"
                message="ver error"
                button-color="error")
      v-row(align="center" justify="space-between")
        v-col
          v-btn(
            v-if="!item.active"
            class="ma-2"
            color="info"
            @click="openUploadForm"
            small)
            v-icon(class="mr-1" dark) mdi-arrow-top-right
            | Realizar Consulta Manual
      v-divider(
        v-if="index < sources.length - 1"
        :key="index")
        
  form-restrictive-list(ref="uploadForm")
  
</template>
  
<script>
// import celeryMixin from '@/mixins/celeryMixin.js';
import { mapState } from 'vuex';
import TextCutter from '@/components/TextCutter';
import ListConventionIcon from '@/components/ui/ListConventionIcon';
import FormRestrictiveList from '@/components/FormRestrictiveList';

export default {
  name: 'RestrictiveList',

  components: {
    TextCutter,
    ListConventionIcon,
    FormRestrictiveList
  },

  // mixins: [celeryMixin],

  computed: {
    ...mapState('restrictions', ['sources', 'identificationTypes', 'reports']),
    selectedSources: {
      get() {
        return this.$store.state.restrictions.selectedSources;
      },
      set(value) {
        return this.$store.commit('restrictions/CHANGE_VALUE', {
          value,
          varName: 'selectedSources'
        });
      }
    },
  },

  data: () => ({
    selected: [2],
    conventions: [
      {
        icon: 'mdi-refresh',
        color: 'blue darken-3',
        text: 'Buscando'
      },
      {
        icon: 'mdi-account-check',
        color: 'green darken-3',
        text: 'No encontrado'
      },
      {
        icon: 'mdi-account-cancel',
        color: 'red darken-3',
        text: 'Encontrado'
      },
      {
        icon: 'mdi-alert',
        color: 'yellow darken-3',
        text: 'Error'
      },
      {
        icon: 'mdi-account-alert',
        color: 'indigo',
        text: 'No encontrado/No Aplica'
      },
    ],
    filteredSources: [],
  }),

  watch: {
    '$store.state.restrictions.selectedProject': {
      handler(newVal) {
        const proyectList = this.reports.filter((x) => x.id === newVal)[0].list_search;
        // filtrar las listas para un reporte
        this.filteredSources = this.sources.filter((x) => proyectList.includes(x.key));
        // seleccionar todos los elementos de las listas del reporte
        const selectedSources = [];
        for (let index = 0; index < this.filteredSources.length; index++) {
            const source = this.filteredSources[index];
            if (source.active) {
                selectedSources.push(index);
            }
        }
        this.selectedSources = selectedSources;
      },
    },
  },
  mounted() {
    // seleccionar el primer reporte de la lista
    this.$store.commit('restrictions/CHANGE_VALUE', {
      value: this.reports[0].id,
      varName: 'selectedProject'
    });
    const proyectList = this.reports.filter((x) => x.id === this.$store.state.restrictions.selectedProject)[0].list_search;
    // filtrar las listas para un reporte
    this.filteredSources = this.sources.filter((x) => proyectList.includes(x.key));
  },
  methods: {
    getTitle(tag) {
      const title = this.identificationTypes.filter((x) => x.id === tag)[0].name;
      return title;
    },
    getSelectedKeys() {
      const realSources = []
      for (let index = 0; index < this.filteredSources.length; index++) {
        const element = this.filteredSources[index];
        if (this.selectedSources.includes(index)) {
          realSources.push(element);
        }
      }
      return realSources.filter((x) => x.active === true).map((x) => x.key);
    },
    openUploadForm() {
      this.$refs.uploadForm.open();
    },
  },
};
</script>

<style>
.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  margin: -60px 0 0 -60px;
  -webkit-animation: spin 2s linear infinite;
  -moz-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }}
</style>