<template lang="pug">
v-row
  v-col(cols="2" class="py-5")
    v-card-subtitle(class="text-button py-0 grey--text text--darken-2")
      div {{title}}
  v-col(cols="4" class="py-0")
    v-card-text(class="text--primary text-h6")
      div {{formatedFolio}}
  v-col(cols="2" class="py-0")
    v-card-text(class="text--primary text-h6")
      div(v-if="formatedFolio === formatedcadastral" class="info-match")
        v-icon(color="green") mdi-check-circle
        | Coinciden
      div(v-else class="info-dismatch")
        v-icon(color="red") mdi-close-box
        | Diferentes
  v-col(cols="4" class="py-0")
    v-card-text(class="text--primary text-h6")
      div {{formatedcadastral}}
    
</template>

<script>
export default {
  name: 'RecordOneComparison',
  props: {
    title: {
      type: [String, Number],
      required: true
    },
    folioInfo: {
      type: String,
      required: true
    },
    cadastralInfo: {
      type: String,
      required: true
    },
  },
  computed: {
    formatedFolio() {
      return this.removeChars(String(this.folioInfo).toUpperCase()).replace('M²', 'm²');
    },
    formatedcadastral() {
      return this.removeChars(String(this.cadastralInfo).toUpperCase()).replace('M²', 'm²');
    }
  },
  methods: {
    removeChars(text){
      const acentos = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
      return text.split('').map( letra => acentos[letra] || letra).join('').toString();	
    }
  }
}
</script>

<style scoped>
.info-match {
  color: #4CAF50;
}
.info-dismatch{
  color: #F44336;
}
</style>
