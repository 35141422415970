<template lang="pug">
v-card
  v-toolbar(
    height="40"
    color="cyan"
    dark)
    v-toolbar-title
      span Localización del predio según autoridad catastral
    v-spacer
    a(href="" id="export" ref="exportLink" hidden)
    v-btn(
      v-if="currentSearch.poligono"
      color="green"
      class="ma-2 white--text"
      @click="getKLM")
      | Descargar kml
      v-icon(
        right
        dark) mdi-cloud-download-outline
  property-visor(
    ref="propertyVisor"
    :coordinates="currentSearch.poligono ? currentSearch.poligono.coordinates[0] : []"
    :cadastral-info="currentSearch")
  v-divider
  div(class="d-flex" v-if="currentSearch.ubicacion")
    v-card-subtitle(class="my-auto")
      div Ubicación
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentSearch.ubicacion}}
    v-card-subtitle(class="my-auto")
      div Tipo Predio
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentSearch.tipo === 'URBAN' ? 'Urbano' : 'Rural'}}
    v-card-subtitle(class="my-auto")
      div Área polígono
    v-card-text(class="text--primary text-body-1 font-weight-bold my-auto")
      div(class="pt-4") {{currentSearch.area.toFixed(2)}} m²
  div(class="d-flex" v-if="currentSearch.matricula")
    v-card-subtitle(class="my-auto")
      div Matrícula Inmobiliaria
    v-card-text(class="text--primary text-h5 font-weight-bold my-auto")
      div(class="pt-4") {{currentSearch.matricula}}
      v-spacer
      folio-checker(:folio-id="currentSearch.folio_id" :folio-number="currentSearch.matricula")

</template>

<script>
import { mapState } from 'vuex';
import folioMixin from '@/mixins/folioMixin.js';
import PropertyVisor from '@/components/PropertyVisor';
import tokml from 'tokml';
import FolioChecker from '@/components/folios/FolioChecker.vue';

export default {
  name: 'SearchLocationInfo',
  
  components: {
    PropertyVisor,
    FolioChecker,
  },

  computed: {
    ...mapState('folios', ['currentSearch']),
  },

  mixins: [folioMixin],

  methods: {
    getKLM() {
      const data = this.$refs.propertyVisor.polygon.toGeoJSON();
      // agregar los atributos al geojson
      let propertyData = {};
      propertyData.tipo = this.currentSearch.tipo === 'URBAN' ? 'Urbano' : 'Rural';
      propertyData.ubicacion = this.currentSearch.ubicacion || '';
      propertyData.base = this.currentSearch.match_database;
      propertyData.area = this.currentSearch.area; 
      propertyData.matricula = this.currentSearch.matricula;

      if (this.currentSearch.registros) {
        propertyData = {...propertyData, ...this.currentSearch.registros};
      }

      data.properties = propertyData;
      var kml = tokml(data);

      var convertedData = 'application/xml;charset=utf-8,' + encodeURIComponent(kml);

      // if you want to use the official MIME type for KML
      // var convertedData = 'application/vnd.google-earth.kml+xml;charset=utf-8,' + 
      // encodeURIComponent(kml);
      const today = new Date();
      let formatDate = today.toISOString().replace('T', '_').replace('Z', '');
      formatDate = formatDate.slice(0, formatDate.length - 4);
      document.getElementById('export').setAttribute('href', 'data:' + convertedData); 
      document.getElementById('export').setAttribute('download',
        `descarga_predio_${formatDate}.kml`);
      this.$refs.exportLink.click();
    }
  }
}
</script>
  