<template lang="pug">
v-row
  v-col(cols="6")
    v-img(src="@/assets/report.png" max-width="200")
  v-col(cols="6")
    v-form(ref="form")
      v-text-field(
        clearable
        dense
        outlined
        @click:clear="reportOwner=''"
        v-model="reportOwner"
        :rules="rules"
        label="Persona que realiza el reporte")
      v-select(
        outlined
        v-model="selectedProject"
        dense
        :items="reports"
        item-text="customer_name"
        item-value="id"
        label="Formato del reporte")
      //- seleccion del proyecto
      div(class="d-flex flex-row")
        label(:class="[\
        'v-label',\
        'ark-switch-label',\
        `${!showProjects ? 'ark-switch-label-selected': ''}`]") Otra instancia
        v-switch(
          class="pt-0"
          v-model="showProjects"
          color="green"
          inset)
        label(:class="[\
        'v-label',\
        'ark-switch-label',\
        `${showProjects ? 'ark-switch-label-selected': ''}`]") Proyecto
      v-autocomplete(
        v-if="showProjects"
        clearable
        outlined
        dense
        :items="projects"
        item-value="nombre"
        item-text="nombre"
        v-model="reportProject"
        label="Proyecto para el que se realiza el reporte"
        required)
        template(v-slot:item="data")
          v-list-item-content
            v-list-item-title(v-html="data.item.nombre")
            v-list-item-subtitle(v-html="data.item.tipo")
      v-text-field(
        v-else
        v-model="stringProject"
        outlined
        dense
        label="Ingrese la instancia a la que va dirigida el reporte")
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ReportPreview',

  data() {
    return {
      selected: [],
      rules: [],
      projectOptions: [],
      // estado que indica si mostrar el select de los proyectos
      showProjects: true
    };
  },

  computed: {
    ...mapState('restrictions', ['reports']),
    ...mapState('arkandha', ['projects']),
    reportOwner: {
      get() {
        return this.$store.state.restrictions.reportOwner;
      },
      set(value) {
        return this.$store.commit('restrictions/CHANGE_VALUE', {
          value,
          varName: 'reportOwner'
        });
      }
    },
    reportProject: {
      get() {
        return this.$store.state.restrictions.reportProject;
      },
      set(value) {
        return this.$store.commit('restrictions/CHANGE_VALUE', {
          value,
          varName: 'reportProject'
        });
      }
    },
    selectedProject:{
      get(){
        return this.$store.state.restrictions.selectedProject;
      },
      set(value){
        return this.$store.commit('restrictions/CHANGE_VALUE', {
          varName: 'selectedProject', 
          value,
        })
      }
    },
    stringProject: {
      get(){
        return this.$store.state.restrictions.stringProject;
      },
      set(value){
        return this.$store.commit('restrictions/CHANGE_VALUE', {
          varName: 'stringProject', 
          value,
        })
      }
    }
  },

  watch: {
    showProjects: {
      handler() {
        this.reportProject = null;
      }
    }
  },

  async mounted() {
    await this.getProjects();
  },
  
  methods: {
    ...mapActions('arkandha', ['getProjects']),
    handleReportChange() {
      // Emite un evento con el índice del reporte seleccionado
      this.$emit('reportSelected', this.selectedProject);
    },
  },
}
</script>

<style scoped>
.identification-card {
  border-radius: 15px!important;
}
.text-id-1 {
  font-size: 0.8rem;
  font-weight: 500;
}
.text-id-2 {
  font-size: 0.65rem;
  font-weight: 500;
}
.text-id-3 {
  font-size: 0.65rem;
  font-weight: 300;
}
.text-id-info {
  font-weight: 500;
}
.ellipse {
  background: #F57F17;
  width: 100%;
  height: 100px;
  bottom: 0px;
  z-index: 0;
  border-top-left-radius: 75%;
  border-top-right-radius: 30%;
  left: 0px;
  position: absolute;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.overlap {
  position: absolute;
  z-index: 1;
}
</style>
  