import Vue from 'vue'
import App from './App.vue'

// Vuetify
import vuetify from './plugins/vuetify'

// Vue-router
import router from './router';

// Vuex
import store from './store';

// import vue-panzoom (acercar y drag en imagenes)
import VueZoomer from 'vue-zoomer';

// estilos meteocolombia
import './assets/css/main.css';

// configuracion del bus de eventos
import eventBus from './plugins/event-bus';

// importar las directivas
import ephemeralDir from '@/directives/ephemeralDirective.js';

// Registro de los plugins
Vue.use(eventBus);
// registrar directivas
Vue.directive('ephemeral', ephemeralDir);
// instalar plugin vue zoomer
Vue.use(VueZoomer);

Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

