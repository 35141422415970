import axios from 'axios';
import { arkandhaApiUrl } from '@/env.js';

export function authHeaders(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

const apiClient = axios.create({
  baseURL: `${arkandhaApiUrl}/`,
  withCredentials: false,
  headers: {
    // Accept: 'application/json',
  },
});

export default apiClient;