import { render, staticRenderFns } from "./FolioVisor.vue?vue&type=template&id=02a8b062&scoped=true&lang=pug"
import script from "./FolioVisor.vue?vue&type=script&lang=js"
export * from "./FolioVisor.vue?vue&type=script&lang=js"
import style0 from "./FolioVisor.vue?vue&type=style&index=0&id=02a8b062&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02a8b062",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VCardText,VIcon})
