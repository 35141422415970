<template lang="pug">
v-container(class="text-center" fluid)
  h1 Consulta masiva de Folios VUR (máximo 50)
  vur-massive-manager
</template>

<script>
import VurMassiveManager from '@/components/vur/VurMassiveManager';
import { mapMutations, mapActions, mapState } from 'vuex';

export default {
  name: 'VurMassiveSearch',

  components: {
    VurMassiveManager
  },

  data: () => ({
  }),

  computed: {
    ...mapState('vur', ['certificates']),
  },

  async mounted() {
    // cargar los circulos
    await this.getCircles();
    const preserveList = this.$route.params.preserveList || this.$route.query.preserveList || false;
    // ver si existen folios en los parametros de la url
    if ('folios' in this.$route.query) {
      // Inicializar el numero de matricula inmobiliaria
      let { folios } = this.$route.query;
      folios = JSON.parse(folios);
      folios = folios.map((x) => {
        return {record: x};
      })
      await this.populateFolios(folios);
      // eliminar el parametro de la ruta
      window.history.replaceState({}, document.title, "/" + "massive-vur");
    }
    console.log('this.$route', this.$route);
    // constante para conservarcertificados cuamdo viene de descargar pdfs
    if (!preserveList) {
      // limpiar certificados
      this.CHANGE_VALUE({
        varName: 'certificates',
        value: []
      });
    }
    this.CHANGE_VALUE({
      varName: 'loadingVur',
      value: false
    });
  },

  methods: {
    ...mapMutations('vur', ['CHANGE_VALUE']),
    ...mapActions('vur', ['populateFolios', 'getCircles']),
  }
};
</script>
  