<template lang="pug">
div
  v-toolbar(v-if="cadastralInfo !== null" dense)
    div(class="d-flex" v-if="'match_database' in cadastralInfo")
      v-icon(:color="`${polygonFound ? 'green' : 'red'}`")
        | {{ polygonFound ? 'mdi-map-marker-radius' : 'mdi-map-marker-off' }}
      v-toolbar-title(:class="[`${polygonFound ? 'green--text' : 'red--text'}`]")
        | {{ polygonFound ? 'Encontrado:' : 'No hubo coincidencia' }}
        span(v-if="polygonFound")
          | {{ targetDb.name }} ({{ targetDb.date }})
    v-spacer

    v-menu(offset-y style="z-index:1000")
      template(v-slot:activator="{ on, attrs }")
        v-btn(
          v-bind="attrs"
          v-on="on"
          outlined
          color="info"
          title="Ver bases Catastrales consultadas")
          | Bases consultadas
          v-icon mdi-database-arrow-down
      v-list(dense)
        v-list-item-group(
          color="primary")
          v-list-item(
            v-for="(item, i) in cadastralInfo.used_databases"
            :key="i")
            v-list-item-icon
              v-icon(
                :color="item.id == cadastralInfo.match_database ? 'green' : ''"
                v-text="item.id == cadastralInfo.match_database ? 'mdi-database-check' : 'mdi-database'")
            v-list-item-content
              v-list-item-title(v-text="item.name")
              v-list-item-subtitle(v-text="item.date")
  div(style="height: 350px; width: 100%; overflow: hidden")
    l-map(
      @click="mapClick"
      v-if="showMap && this.coordinates.length > 0"
      :zoom="zoom"
      :center="trueCoordinates[0]"
      :options="mapOptions"
      ref="myMap"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      @ready="lefletMapReady()")
      l-control-fullscreen(
        position="topleft"
        :options="fullscreenOptions")
      l-control-layers
      l-control(
        class="example-custom-control clickable"
        title="Encuadrar el predio")
        v-icon(
          class="pt-1 pl-1"
          size="35"
          @click="focusProperty"
          color="grey darken-1"
          ) mdi-target
      l-control-scale(position="bottomleft" :imperial="false" :metric="true")
      l-wms-tile-layer(
        v-for="l in layers"
        :key="l.name"
        :base-url="l.url"
        :layer-type="l.type"
        :layers="l.layers"
        :transparent="l.transparent"
        format="image/png"
        :name="l.name")
      l-polygon(
        ref="polygon"
        :weight="4"
        :fill-color="polygonColor"
        :lat-lngs="trueCoordinates"
        @ready="polygonReady"
        :color="polygonColor")
    div(
      v-else
      style="background:#80CBC4; height:100%"
      class="text-body-2 d-flex flex-column text-center pt-16")
      v-icon(size="50") mdi-map-marker-off-outline
      | {{ message }}
</template>

<script>
import { latLng } from "leaflet";
import {
  LMap,
  LWMSTileLayer,
  LPolygon,
  LControlLayers,
  LControl,
  LControlScale,
} from "vue2-leaflet";
import LControlFullscreen from 'vue2-leaflet-fullscreen';
import mapMixin from '@/mixins/mapMixin.js';

export default {
  name: 'PropertyVisor',

  components: {
    LMap,
    LPolygon,
    LControlLayers,
    'l-wms-tile-layer': LWMSTileLayer,
    LControl,
    LControlScale,
    LControlFullscreen,
  },

  mixins: [mapMixin],

  props: {
    coordinates: {
      type: Array,
      required: true
    },
    message: {
      type: String,
      default: 'No fue posible encontrar el poligono del predio'
    },
    reverseCoords: {
      type: Boolean,
      default: true
    },
    // propieda que recibe los datos de la infomación de bases catastrales
    cadastralInfo: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    withPopup: latLng(47.41322, -1.219482),
    withTooltip: latLng(47.41422, -1.250482),
    showParagraph: false,
    showMap: false,
    map: null,
  }),

  computed: {
    trueCoordinates() {
      if (this.coordinates.length < 1) {
        return;
      }
      let coords;
      if (this.reverseCoords === true) {
        try {
          coords = this.coordinates[0].map((x) => x.reverse());
        } catch (error) {
          coords = this.coordinates.map((x) => x.reverse());
        }
      } else {
        coords = this.coordinates[0].map((x) => {
          return {lat:x[1], lng:x[0]}});
      }
      
      return coords;
    },
    polygonFound() {
      if (this.cadastralInfo === null) return false;
      if (!('match_database' in this.cadastralInfo)) return false;
      return this.cadastralInfo.match_database !== '' ? true : false;
    },
    targetDb() {
      return this.cadastralInfo.used_databases.filter(
        (x) => x.id === this.cadastralInfo.match_database
      )[0];
    }
  },

  async mounted() {
    this.showMap = await true;
  },

  methods: {
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
    // metodo para mostrar la información de catastro de la capa de predios 
    // metodo para agregar coordena con click en el mapa
    mapClick(e) {
      // si no esta habilitado el dibujo consultar el serviscio WMS
      this.getFeatureInfo(
        e,
        'ptp_rural_catastro_2017',
        'https://geovisor.arkandha.net/geoserver/geonode/wms',
        ['codigo', 'codigo_anterior']);
    },
  }
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
}
.example-custom-control {
background: #fff;
width: 48px;
height: 48px;
/* padding: 0 0.5em; */
border: 1px solid #aaa;
border-radius: 4px;
}
</style>