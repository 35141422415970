<template lang="pug">
v-card
  v-toolbar(
    height="40"
    :color="folioColor"
    dark)
    v-toolbar-title Listado propietarios VUR <b>({{ owners.length }}) Personas</b>
  v-row(class="mx-4 mt-3")
    v-col <b>NÚMERO DOCUMENTO</b>
    v-col <b>TIPO IDENTIFICACIÓN</b>
    v-col <b>NOMBRES-APELLIDOS (RAZÓN SOCIAL)</b>
    v-col <b>PARTICIPACIÓN</b>
  div(class="truncated-folio-detail-height scrollable")
    v-row(
      class="mx-4 my-0"
      v-for="(owner, i) in owners"
      :key="`vur-owner-${i}`")
      v-col(v-if="owner.numeroDocumento !== ''") {{ owner.numeroDocumento }}
      v-col(v-else) Sin identificar
      v-col(v-if="'tipoDocumento' in owner") {{ owner.tipoDocumento.nombre }}
      v-col(v-else) Sin tipo
      v-col {{ owner.nombre }}
      v-col {{ owner.porcentajeParticipacion }}
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import ProgresSlider from '@/components/ProgresSlider.vue';

export default {
  name: 'VurOwnersInfo',
  props: {
    owners: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters('folios', ['folioColor']),
    ...mapState('folios', ['currentFolio']),
  },
  data: () => ({
  }),
  components: {
    ProgresSlider
  },
  methods: {
    ...mapMutations('vur', ['DELETE_CERTIFICATE', 'SELECT_CERTIFICATE']),
  }
}
</script>