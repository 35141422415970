import { latLng, icon } from "leaflet";
import { mapState } from 'vuex';
import L from "leaflet";

export default {
  data: () => ({
    center: latLng(5.047269, -73.083070),
    // variable que almacena el zoom actual
    currentZoom: 11.5,
    // variable que almacena la coordenada del centro del mapa
    currentCenter: latLng(5.047269, -73.083070),
    // objeto que almacena el mapa
    map: null,
    // configuracion del mapa
    mapOptions: {
      zoomSnap: 0.5
    },
    // valor del nivel de acercamiento del mapa
    zoom: 13,
    // icono del marcador leaflet
    icon: icon({
      iconUrl: require('@/assets/marker-icon.png'),
      shadowUrl: require('@/assets/marker-shadow.png'),
      iconSize: [25, 37],
      iconAnchor: [16, 37]
    }),
    // eatdo con los bound del mapa
    bounds: null,
    // eatdo para un polygono
    polygon: null,
    // color del polygono
    polygonColor: '#F44336',
    // opciones para el control de pantalla completa
    fullscreenOptions: {
      title: {
        'false': 'Activar el modo pantalla completa',
        'true': 'Salir del modo pantalla completa',
      },
    },
    // propieda con el zoom por defecto a una coordenada manual
    pointDetailZoom: 13,
  }),

  computed: {
    ...mapState('geos', ['layers', 'markers']),
  },

  methods: {
    // metodo que se lanza cuando el zoom se actualiza
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    // metodo que se lanza cuando el centro se actualiza
    centerUpdate(center) {
      this.currentCenter = center;
    },
    // metodo que se lanza cuando el
    lefletMapReady() {
      this.map = this.$refs.myMap.mapObject;
      setTimeout(() => {
        this.map.invalidateSize();
      }, 0);
    },
    // cuando un polygono esta listo
    polygonReady() {
      this.polygon = this.$refs.polygon.mapObject;
      this.bounds = this.polygon._bounds;
      setTimeout(() => {
        this.map.fitBounds(this.bounds);
      }, 100);
    },
    // metodo para enficar el poligono opera con el control
    focusProperty() {
      this.map.fitBounds(this.bounds);
    },
    // metodo para cambiar el zoom y extension del mapa por medio de marcadores
    markerExtend(markerName) {
      const marker = this.markers[markerName];
      // cambiar el centro y el zoom del mapa
      this.center = latLng(marker.lat, marker.lng);
      this.zoom = marker.zoom;
    },
    // metodo para conseguir la informacion wms
    async getFeatureInfo(event, layer, wmsUrl, fields) {
      const point = this.map.latLngToContainerPoint(event.latlng);
      const bounds = this.map.getBounds();
      const size = this.map.getSize();
      
      // Construcción de la URL de GetFeatureInfo
      const params = {
        SERVICE: "WMS",
        VERSION: "1.1.1",
        REQUEST: "GetFeatureInfo",
        TRANSPARENT: "true",
        QUERY_LAYERS: layer,
        LAYERS: layer,
        INFO_FORMAT: "application/json", // También puedes usar "text/html" o "text/plain"
        FEATURE_COUNT: 10,
        X: Math.round(point.x),
        Y: Math.round(point.y),
        SRS: "EPSG:4326",
        WIDTH: size.x,
        HEIGHT: size.y,
        BBOX: `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`,
      };

      if (fields) {
        params['PROPERTYNAME'] = fields.join(',')
      }

      const url = `${wmsUrl}?${new URLSearchParams(params).toString()}`;
      try {
        const response = await fetch(url, {
          mode: "cors"
        });
        const data = await response.json();
        const estates = data.features;
        let strTemp = '';
        for (let index = 0; index < estates.length; index++) {
          const estate = estates[index];
          strTemp += `
          <b>Predio ${index + 1}</b><br>
          <div style="padding-bottom: 5px;">
          <b>NPN:</b> <span class="mr-2">${estate.properties.codigo}</span>
          <a class="ark-lf-button" target="_blank" href='/search?code=${estate.properties.codigo}'>Buscar</a><br>
          </div>
          <div style="padding-bottom: 10px;">
          <b>NPN anterior:</b> <span class="mr-2">${estate.properties.codigo_anterior}</span>
          <a class="ark-lf-button" target="_blank" href='/search?code=${estate.properties.codigo_anterior}'>Buscar</a><br>
          </div>
          `;
        }
        if (estates.length === 0) strTemp = 'No hubo coincidencias';
        // Puedes mostrar los datos en un popup, modal o cualquier otro componente.
        L.popup()
        .setLatLng(event.latlng)
        .setContent(strTemp)
        // .setContent(`<b>Coordenadas:</b> ${event.latlng.lat}, ${event.latlng.lng}`)
        .openOn(this.map); // Se añade al mapa
      } catch (error) {
        console.error("Error en GetFeatureInfo:", error);
      }
    },
  }
}