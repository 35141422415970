<template lang="pug">
v-row
  v-col(cols="6" sm="12" md="6")
    v-card(
      color="yellow lighten-2"
      class="mx-auto identification-card"
      elevation="4"
      min-width="330"
      max-width="330"
      min-height="180"
      max-height="180"
      outlined)
      v-row(class="pa-3")
        v-col(class="overlap" cols="7" style="left:0px")
          div
            div(class="text-id-1") {{ cardTitle }}
            div(class="text-id-2") IDENTIFICACION ÚNICA
            div(class="text-id-3") Persona o Empresa
          div(class="text-left mt-4")
            div(class="text-id-3") {{ naturalPerson ? 'Nombres' : 'Razón Social'}}
            div(class="text-id-info") {{fullName !== '' ? fullName : 'Ingrese nombre'}}
            div(class="text-id-3") Identificación
            div(class="text-id-info") {{targetData.identification !== '' ? fullIdentification : 'Ingrese identificación'}}
        v-col(class="overlap" cols="5" style="right:0px")
          v-img(
            class="mt-3"
            src="@/assets/male.png")
        div(class="ellipse")
  v-col(cols="6" sm="12" md="6")
    v-form(ref="form")
      v-row
        v-col(cols="12")
          div(class="d-flex flex-row")
            label(:class="[\
            'v-label',\
            'ark-switch-label',\
            `${!naturalPerson ? 'ark-switch-label-selected': ''}`]") Persona Jurídica
            v-switch(
              class="pt-0"
              v-model="naturalPerson"
              color="green"
              inset)
            label(:class="[\
            'v-label',\
            'ark-switch-label',\
            `${naturalPerson ? 'ark-switch-label-selected': ''}`]") Persona Natural
        v-col(cols="6")
          v-text-field(
            v-model="targetData.firstName"
            outlined
            :rules="rules"
            dense
            :label="naturalPerson ? 'Primer Nombre' : 'Razón Social'")
        v-col(cols="6" v-if="naturalPerson")
          v-text-field(
            v-model="targetData.secondName"
            outlined
            :rules="rules"
            dense
            label="Segundo Nombre")
        v-col(cols="6" v-if="naturalPerson")
          v-text-field(
            v-model="targetData.firstSurname"
            outlined
            :rules="rules"
            dense
            label="Primer Apellido")
        v-col(cols="6" v-if="naturalPerson")
          v-text-field(
            v-model="targetData.secondSurname"
            outlined
            :rules="rules"
            dense
            label="Segundo Apellido")
        v-col(cols="6")
          v-text-field(
            v-model="targetData.identification"
            :rules="rules"
            outlined
            dense
            label="Número de identificación")
        v-col(cols="6")
          v-select(
            v-model="targetData.identificationType"
            :items="filterIdentificationTypes"
            outlined
            item-text="name"
            item-value="id"
            label="Tipo de identificación"
            dense
            )
        v-col(cols="6" v-if="showExpeditionDate")
          v-menu(offset-y)
            template(v-slot:activator="{ on, attrs }")
              v-text-field(
                v-model="targetData.identificationDate = selectedDate"
                outlined
                dense
                label="Fecha de expedición documento"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on")
            v-date-picker(
              v-model="picker"
              color="green lighten-1")
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'IdentificationPreview',

  props: {
    cardTitle: {
      type: String,
      default: 'LISTAS RESTRICTIVAS'
    },
    showExpeditionDate: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    selected: [2],
    rules: [],
    picker: null,
  }),

  watch: {
    '$store.state.restrictions.naturalPerson': {
      deep: true,
      handler() {
        this.CHANGE_INITIAL_ID_TYPE();
        // vaciar los datos de la consulta
        this.CLEAR_NAMES();
      }
    }
  },

  computed: {
    ...mapState('restrictions', ['identificationTypes']),
    selectedDate() {
      let computedDate = '';
      if (this.picker) {
        computedDate = this.picker;
      }
      return computedDate
    },
    targetData: {
      get() {
        return this.$store.state.restrictions.targetData;
      },
      set(value) {
        return this.$store.commit('restrictions/CHANGE_VALUE', {
          value,
          varName: 'targetData'
        });
      }
    },
    naturalPerson: {
      get() {
        return this.$store.state.restrictions.naturalPerson;
      },
      set(value) {
        return this.$store.commit('restrictions/CHANGE_VALUE', {
          value,
          varName: 'naturalPerson'
        });
      }
    },
    fullName() {
      let names = [this.targetData.firstName, this.targetData.secondName,
        this.targetData.firstSurname, this.targetData.secondSurname];
      names = names.filter((x) => x !== '');
      return names.join(" ");
    },
    fullIdentification() {
      return [this.targetData.identificationType, this.targetData.identification].join(" ");
    },
    filterIdentificationTypes() {
      const currentPerson = this.naturalPerson ? 'natural' : 'company';
      return this.identificationTypes.filter((x) => x.person === currentPerson);
    }
  },

  methods: {
    ...mapMutations('restrictions', ['CHANGE_INITIAL_ID_TYPE', 'CLEAR_NAMES'])
  }

}
</script>

<style scoped>
.identification-card {
  border-radius: 15px!important;
}
.text-id-1 {
  font-size: 0.8rem;
  font-weight: 500;
}
.text-id-2 {
  font-size: 0.65rem;
  font-weight: 500;
}
.text-id-3 {
  font-size: 0.65rem;
  font-weight: 300;
}
.text-id-info {
  font-weight: 500;
}
.ellipse {
  background: #F57F17;
  width: 100%;
  height: 100px;
  bottom: 0px;
  z-index: 0;
  border-top-left-radius: 75%;
  border-top-right-radius: 30%;
  left: 0px;
  position: absolute;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.overlap {
  position: absolute;
  z-index: 1;
}
</style>
